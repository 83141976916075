import IdleJs from 'idle-js';

interface IdleOptions {
  idle: number;
  onIdle: () => void;
}

export interface IdleInstance {
  reset?: () => void;
  start?: () => void;
  stop?: () => void;
}

export interface IdleSession {
  idleAlert: IdleInstance;
  idleWarning: IdleInstance;
}

export const initIdleInstance = ({
  idle,
  onIdle
}: {
  idle: number;
  onIdle: () => void;
}) =>
  new IdleJs({
    events: ['keydown', 'mousedown', 'touchstart'],
    idle,
    keepTracking: true,
    onIdle,
    startAtIdle: false
  });

export const initSession = (
  alertOptions: IdleOptions,
  warningOptions: IdleOptions
): IdleSession => {
  const idleAlert = initIdleInstance(alertOptions);
  const idleWarning = initIdleInstance(warningOptions);

  return { idleAlert, idleWarning };
};

export const startSession = (_session: IdleSession) => {
  _session.idleAlert.start();
  _session.idleWarning.start();
};

export const stopSession = (_session: IdleSession) => {
  _session.idleAlert.stop();
  _session.idleWarning.stop();
};

export const resetSession = (_session: IdleSession) => {
  _session.idleAlert.reset();
  _session.idleWarning.reset();
};

export const restartSession = (_session: IdleSession) => {
  _session.idleAlert.stop();
  _session.idleAlert.reset();
  _session.idleAlert.start();

  _session.idleWarning.stop();
  _session.idleWarning.reset();
  _session.idleWarning.start();
};

export default {
  initSession,
  startSession,
  stopSession,
  restartSession
};
