import { CommentState, Comment } from 'state/comments/types';
import { mergeRight } from 'ramda';

export default {
  many(state: CommentState, comments: Comment[]) {
    state.comments = comments;
  },
  updateComment(state: CommentState, comment: Comment) {
    state.comment = comment;
  },
  loadDocumentsComments(state: CommentState, payload: any) {
    state.documentsComments = mergeRight(state.documentsComments, { [payload.id]: payload.comments.length });
  },
  addDocumentComment(state: CommentState, documentId: string) {
    Object.keys(state.documentsComments).forEach(key => {
      if (key === documentId) state.documentsComments[key]++;
    });
  }
};
