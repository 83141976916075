import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface ActionItem extends FormElement {
  type: 'ActionItem';
  name: string;
  label: string;
  required: boolean;
  children: object;
}

const service: FormBuilderElementService<ActionItem> = {
  getEmptyElement: (id: string): ActionItem => {
    return {
      id,
      name: '',
      label: 'Are additional actions required?',
      type: 'ActionItem',
      required: false,
      children: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
      ]
    };
  }
};

export default service;
