import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface ColumnField extends FormElement {
  type: string;
  name: string;
  label: string;
  placeholder?: string;
  required: boolean;
  live_process_column_index: number;
  format: string;
  children: object;
}

const service: FormBuilderElementService<ColumnField> = {
  getEmptyElement: (id: string, text: string, live_process_column_index: number): ColumnField => {
    return {
      id,
      name: '',
      label: text,
      type: 'LiveProcessColumnField',
      required: false,
      live_process_column_index: live_process_column_index,
      format: 'text',
      children: [
        { text: 'Option A', value: 'Option A' },
        { text: 'Option B', value: 'Option B' },
        { text: 'Option C', value: 'Option C' }
      ]
    };
  }
};

export default service;
