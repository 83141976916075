import state from 'admin/state/task_rules/state';
import mutations from 'admin/state/task_rules/mutations';
import actions from 'admin/state/task_rules/actions';

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
