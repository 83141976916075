import { BaseState, buildState, clearModel, errors, one, many, progress, updateModel } from 'admin/state/base_store';
import Fuse from 'fuse.js';

export interface JobRoleState extends BaseState {
  job_roles: JobRole[];
  job_role: JobRole;
}

interface OneJobRole {
  job_role: JobRole;
}

interface PaginatedJobRoles extends PaginatedResources{
  job_roles: JobRole[];
}

const JobRoleStore = {
  namespaced: true,
  state: buildState<JobRoleState>({
    job_roles: [],
    job_role: {} as JobRole
  }),
  getters: {
    searchJobRoles: (state) => ({ text }) => {
      const options = {
        keys: ['name'],
        threshold: 0.3
      };
      const fuse = new Fuse(state.job_roles, options);

      return text ? fuse.search(text) : state.job_roles;
    }
  },
  mutations: {
    one: one<JobRoleState, OneJobRole>('job_role'),
    many: many<JobRoleState, PaginatedJobRoles>('job_roles'),
    progress,
    errors,
    updateModel: updateModel<JobRoleState>('job_role'),
    clearModel: clearModel<JobRoleState>('job_role')
  },
  actions: {
    index(context, query = {}) {
      return new Promise((resolve, _reject) => {
        const opts = {
          per_page: Number.MAX_SAFE_INTEGER
        };
        Object.keys(query).forEach((prop) => {
          opts[`q[${prop}_cont]`] = query[prop];
        });
        $.ajax({
          url: 'job_roles',
          type: 'get',
          data: opts,
          success: (data) => {
            context.commit('many', data);
            resolve(data.job_roles);
          },
          error: (err) => {
            console.error('err', err);
          }
        });
      });
    },
    autocomplete(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'job_roles/autocomplete',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data.job_roles);
          },
          error: reject
        });
      });
    },
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/job_roles',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    new(context) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/job_roles/new',
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    create(context, form) {
      context.commit('progress', 'loading');

      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/job_roles',
          type: 'post',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(form),
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (err) => {
            context.commit('progress', 'failed');
            context.commit('errors', err);
            reject(err);
          }
        });
      });
    },
    edit(context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/job_roles/${id}/edit`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    update(context, jobRole) {
      context.commit('progress', 'loading');

      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/job_roles/${jobRole.id}`,
          type: 'put',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify({ job_role: jobRole }),
          success: (data) => {
            context.commit('progress', 'success');
            context.commit('one', data);
            resolve(data);
          },
          error: (err) => {
            context.commit('progress', 'failed');
            context.commit('errors', err);
            return reject(err);
          }
        });
      });
    },
    destroy(_context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/job_roles/${id}`,
          type: 'delete',
          success: resolve,
          error: reject
        });
      });
    }
  }
};

export default JobRoleStore;
