import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';
import { LOCAL_TIMEZONE, FDA_DATE_FORMAT, FDA_DATETIME_FORMAT } from 'helpers/time';

export interface DateField extends FormElement {
  type: 'DateField';
  name: string;
  label: string;
  format: typeof FDA_DATE_FORMAT | typeof FDA_DATETIME_FORMAT;
  minDate?: Date;
  timeZone: string;
  required: boolean;
  content: string;
}

const service: FormBuilderElementService<DateField> = {
  getEmptyElement: (id: string): DateField => {
    return {
      id,
      name: '',
      label: 'Date / Time',
      type: 'DateField',
      timeZone: LOCAL_TIMEZONE,
      format: FDA_DATE_FORMAT,
      required: false,
      content: null
    };
  }
};

export default service;
