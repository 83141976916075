import { ProposalReviewState, ProposalReview } from 'state/proposal_reviews/types';
import { clone } from 'ramda';

const mutations = {
  ADD_EDIT_REVIEW(state: ProposalReviewState, newReview: ProposalReview) {
    state.editReviews = [...state.editReviews, newReview]
  },
  SET_EDIT_REVIEWS(state: ProposalReviewState, reviews: ProposalReview[]) {
    state.editReviews = reviews;
  },
  CLEAR_EDIT_REVIEWS(state: ProposalReviewState) {
    state.editReviews = [];
  },
  SET_EDIT_REVIEW_ORDER_IS_ON(state: ProposalReviewState, status: boolean) {
    state.editReviewOrderIsOn = status;
  },
  UPDATE_PROPOSAL_REVIEW(state: ProposalReviewState, { key, value }) {
    state.proposalReview = { ...state.proposalReview, [key]: value };
  },
  CLEAR_PROPOSAL_REVIEW(state: ProposalReviewState) {
    state.proposalReview = null;
  },
  SET_CANCELLED_REVIEWS(state: ProposalReviewState, reviews: ProposalReview[]) {
    state.cancelledReviews = reviews;
  },
  REMOVE_CANCELLED_REVIEW(state: ProposalReviewState, removedReview: ProposalReview) {
    const cancelledReviews: ProposalReview[] = clone(state.cancelledReviews)
    state.cancelledReviews = cancelledReviews.filter((review) => review.approver.approverId !== removedReview.approver.approverId);
  },
  CLEAR_CANCELLED_REVIEWS(state: ProposalReviewState) {
    state.cancelledReviews = [];
  },
}

export default mutations;
