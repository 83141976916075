import { VersionState, Version, ProposalStatus } from 'state/versions/types';
import { ProposalReviewStatus } from 'state/proposal_reviews/types';

const awaitingMyReview = (version: Version, currentUser: User) => {
  const reviews = version.draftProposal.proposalReviews;

  const myReview = reviews.find((review) => review.approver.approverId === currentUser.id)
  if(myReview === undefined || [ProposalReviewStatus.Approved, ProposalReviewStatus.ChangesRequested].includes(myReview.reviewStatus)) return false;

  return reviews
    .filter(r => r.reviewOrderIndex < myReview.reviewOrderIndex)
    .every(r => r.reviewStatus === ProposalReviewStatus.Approved)
}

export default {
  all: (state: VersionState) =>
    state.ids.map((id: string) => state.byId[id]),

  drafts: (state: VersionState) =>
    state.draftIds.map((id: string) => state.draftsById[id]),

  trashed: (state: VersionState) =>
    state.trashedIds.map((id: string) => state.trashedById[id]),

  folders: (state: VersionState) =>
    state.folderIds.map((id: string) => state.byFolderId[id]),

  filter: () => (versions: any, currentUser: User, searchFilters: object) => {
    let filteredVersions = versions

    if (searchFilters['authorId'] !== '') {
      filteredVersions = filteredVersions.filter((version) => version['authorId'] === searchFilters['authorId'])
    }

    switch (searchFilters['approvalStatus']) {
    case 'no_reviews':
      filteredVersions = filteredVersions.filter((version) => version.draftProposal.proposalReviews.length === 0)
      break;
    case 'reviewed_by_me':
      filteredVersions = filteredVersions
        .filter((version) => version.draftProposal.proposalReviews
          .some((review) => review.approver.approverId === currentUser.id && review.reviewStatus !== ProposalReviewStatus.Pending && review.reviewStatus !== ProposalReviewStatus.Cancelled))
      break;
    case 'awaiting_my_review':
      filteredVersions = filteredVersions.filter((version) => awaitingMyReview(version, currentUser))
      break;
    case 'requested_changes':
      filteredVersions = filteredVersions.filter((version) => version.draftProposal.status === ProposalStatus.RequestedChanges )
      break;
    case 'approved':
      filteredVersions = filteredVersions.filter((version) => version.draftProposal.status === ProposalStatus.Approved )
      break;
    }

    return filteredVersions
  }
};
