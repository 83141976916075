import { clone } from 'ramda';
import { UpdateProposalReviewsArgs, NotifyReviewersPayload, ProposalReviewStatus, ProposalReview } from 'state/proposal_reviews/types';

const actions = {
  setEditReviews({ commit }, reviews: ProposalReview[]) {
    reviews = clone(reviews)
    commit('SET_EDIT_REVIEWS', reviews);
  },

  addEditReview({ commit, state }, newReview: ProposalReview)  {
    const existingReviews: ProposalReview[] = clone(state.cancelledReviews).filter((review) => review.approver.approverId === newReview.approver.approverId)

    // If there is an existing review. rather than make a duplicate, we want to move it from the 'cancelledReviews' list
    // back in to the 'editReviews' list but change its status and index order like it was new.
    if(existingReviews.length > 0) {
      commit('REMOVE_CANCELLED_REVIEW', existingReviews[0]);
      const cloned: ProposalReview = clone(existingReviews[0]);
      cloned.reviewOrderIndex = state.editReviews.length
      cloned.reviewStatus = cloned.reviewStatus === ProposalReviewStatus.ToCancel ? ProposalReviewStatus.Restored : ProposalReviewStatus.New;
      commit('ADD_EDIT_REVIEW', cloned);
    } else {
      commit('ADD_EDIT_REVIEW', newReview);
    }
  },

  clearEditAndCancelReviews({ commit }) {
    commit('CLEAR_EDIT_REVIEWS');
    commit('CLEAR_CANCELLED_REVIEWS');
  },

  setEditReviewOrderIsOn({ commit }, status: boolean)  {
    commit('SET_EDIT_REVIEW_ORDER_IS_ON', status);
  },

  removeReview({ commit, state }, reviewIndex: number) {
    // Remove the target review from the list and update all of the reviewOrderIndicies.
    let reviews: ProposalReview[] = clone(state.editReviews)
    const removedReview: ProposalReview = reviews.splice(reviews.findIndex((review) => review.reviewOrderIndex === reviewIndex), 1)[0]
    reviews = reviews.map((review, index) => {return { ...review, reviewOrderIndex: index }})

    // If the removed review has already been created, put it into the list of removedReviews
    // so it can be sent to the backend for cancellation
    if(removedReview.reviewStatus !== ProposalReviewStatus.New) {
      const cloned: ProposalReview = clone(removedReview);
      cloned.reviewOrderIndex = 0;
      cloned.reviewStatus = ProposalReviewStatus.ToCancel;
      commit('SET_CANCELLED_REVIEWS', [...state.cancelledReviews, cloned])
    }

    commit('SET_EDIT_REVIEWS', reviews);
  },

  moveUp({ commit, state }, reviewIndex: number) {
    const reviews: ProposalReview[] = clone(state.editReviews)

    // ("bump up" implies going earlier in the order, so lower in the review index)
    const bumpUp: ProposalReview = reviews.filter((x) => x.reviewOrderIndex === reviewIndex)[0]
    const bumpDown: ProposalReview  = reviews.filter((x) => x.reviewOrderIndex === reviewIndex - 1)[0]

    bumpUp.reviewOrderIndex--
    bumpDown.reviewOrderIndex++

    commit('SET_EDIT_REVIEWS', reviews);
  },

  moveDown({ commit, state }, reviewIndex: number) {
    const reviews: ProposalReview[] = clone(state.editReviews)

    // ("bump down" implies going later in the order, so higher in the review index)
    const bumpDown: ProposalReview  = reviews.filter((x) => x.reviewOrderIndex === reviewIndex)[0]
    const bumpUp: ProposalReview  = reviews.filter((x) => x.reviewOrderIndex === reviewIndex + 1)[0]

    bumpDown.reviewOrderIndex++
    bumpUp.reviewOrderIndex--

    commit('SET_EDIT_REVIEWS', reviews);
  },

  manageProposalReviews(_, args: UpdateProposalReviewsArgs ) {
    return $.ajax({
      url: `documents/documents/${args.documentId}/versions/${args.versionId}/proposals/${args.proposalId}/manage_proposal_reviews`,
      type: 'put',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({ reviews: args.reviews, cancelReviews: args.cancelReviews, enforceReviewOrder: args.enforceReviewOrder }),
      success: data => {
        Promise.resolve(data);
      },
      error: err => {
        Promise.reject(err);
      }
    });
  },

  async notifyReviewers(_, payload: NotifyReviewersPayload ) {
    const NotificationPromises = payload.reviewIds.map((reviewId) => {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `documents/documents/${payload.documentId}/versions/${payload.versionId}/proposals/${payload.proposalId}/proposal_reviews/${reviewId}/notify_reviewer`,
          type: 'post',
          success: resolve,
          error: reject
        });
      });
    })
    return Promise.all(NotificationPromises)
  },

  reviewProposal(_, review: ProposalReview) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/proposal_reviews/${review.proposalReviewId}/proposal_review`,
        type: 'post',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
          review: {
            review_status: review.reviewStatus,
            review_comment: review.reviewComment
          }
        }),
        success: resolve,
        error: reject
      });
    });
  },
};

export default actions;
