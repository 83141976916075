import actions from 'state/toast/actions';
import getters from 'state/toast/getters';
import mutations from 'state/toast/mutations';
import state from 'state/toast/state';

const ToastStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default ToastStore;
