import { LoadProgress } from 'admin/helpers';
import { ProcessState } from 'state/processes/types';
import { none } from 'fp-ts/lib/Option';

const state: ProcessState = {
  progress: LoadProgress.Init,
  submissionProgress: LoadProgress.Init,
  summaryProgress: LoadProgress.Init,
  historyProgress: LoadProgress.Init,
  submissionErrors: {},
  process: null,
  errors: {},
  submissionInfo: null,
  submissionInfoCache: null,
  submissionsGrid: null,
  selectedSubmission: none,
  processes: null,
  stepModel: { title: '' },
  stepProgress: LoadProgress.Init,
  stepErrors: {},
  tasksToPreValidate: {},
  taskModel: { title: '' },
  taskProgress: LoadProgress.Init,
  taskErrors: {},
  history: [],
  sendPdfModel: { recipients: [] },
  sendPdfProgress: LoadProgress.Init,
  sendPdfErrors: {},
  sendSubmissionPdfProgress: LoadProgress.Init,
  sendSubmissionPdfErrors: {}
};

export default state;
