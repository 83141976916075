
























































































import { mapGetters } from 'vuex';
import { getOrElse, Option } from 'fp-ts/lib/Option';
import NavMenu from 'components/shared/nav_menu.vue';

export default {
  components: { NavMenu },

  data() {
    return {
      locale: (window as any).I18n.locale,
      availableLocales: (window as any).I18n.availableLocales
    };
  },

  inject: {
    singleSignOn: {
      default: () => () => false
    },
    localizationOverrides: {
      default: () => () => ({})
    },
    csrfToken: {
      default: () => () => null
    }
  },
  
  computed: {
    ...mapGetters('UserStore', ['currentUser']),
    isLoggedIn() {
      return Boolean(window.currentUser);
    },
    user(): User | null {
      return getOrElse(() => null)(this.currentUser as Option<User>);
    },
    title() {
      return this.localizationOverrides()?.title || this.$t('title');
    }
  },

  watch: {
    locale(locale) {
      let redirectTo = `/${locale}${this.$route.path}`;
      if (locale === this.availableLocales[0]) {
        redirectTo = `${this.$route.path}`;
      }

      window.location.href = redirectTo;
    }
  },

  methods: {
    activeOn(paths) {
      if (paths.includes(this.$route.name)) {
        return 'active';
      } else {
        return '';
      }
    },
    signIn() {
      window.location.href = this.singleSignOn() ? '/users/auth/sso' : '/users/sign_in';
    }
  }
};
