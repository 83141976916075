import { BaseState, buildState, baseAssetMutations } from 'admin/state/base_store';

interface MachineState extends BaseState {
  machines: Machine[];
  machine: Machine;
}

interface OneMachine {
  machine: Machine;
}

interface PaginatedMachines extends PaginatedResources{
  machines: Machine[];
}

const MachineStore = {
  namespaced: true,
  state: buildState<MachineState>({
    machines: [],
    machine: { status: 'published' }
  }),
  mutations: baseAssetMutations<MachineState, OneMachine, PaginatedMachines>('machine'),
  actions: {
    index(context, query) {
      $.ajax({
        url: `admin/machine_types/${query.machine_type_id}/machines`,
        type: 'get',
        data: query,
        success: (data) => {
          context.commit('many', data);
        }
      });
    },
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${query.machine_type_id}/machines`,
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    autocomplete(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${query.machine_type_id}/machines/autocomplete`,
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data.machines);
          },
          error: reject
        });
      });
    },
    paginatedByLWA(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machines/lwa/${query.lwa_id}/machines`,
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    new(context, machineTypeId) {
      $.ajax({
        url: `admin/machine_types/${machineTypeId}/machines/new`,
        type: 'get',
        success: (data) => {
          context.commit('one', data);
        }
      });
    },
    create(context, { machine, machine_type_id }) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${machine_type_id}/machines`,
          type: 'post',
          data: {
            machine
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    edit(context, params) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${params.machine_type_id}/machines/${params.id}/edit`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    update(context, machine) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${machine.machine_type_id}/machines/${machine.id}`,
          type: 'put',
          data: {
            machine
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    destroy(_context, { machine_type_id, machine_id }) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${machine_type_id}/machines/${machine_id}`,
          type: 'delete',
          success: (data) => {
            resolve(data);
          },
          error: (data) => {
            reject(data);
          }
        });
      });
    }
  }
};

export default MachineStore;
