import { ProposalReviewState } from 'state/proposal_reviews/types';''

const defaultState: ProposalReviewState = {
  editReviews: [],
  editReviewOrderIsOn: false,
  proposalReview: null,
  cancelledReviews: [],
};

export default defaultState;
