export default {
  getTaskRuleForm(context, { id, version }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/forms/${id}/${version}`,
        type: 'get',
        success: (data) => {
          context.commit('setTaskRuleForm', data);
          resolve(data);
        },
        error: reject
      });
    });
  },
  getSummaryRuleForm(context, { id, version }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/forms/${id}/${version}`,
        type: 'get',
        success: (data) => {
          context.commit('setTaskRuleForm', data);
          resolve(data);
        },
        error: reject
      });
    });
  }
};
