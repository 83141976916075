import { ProcessState, SubmissionInfo, TaskError } from 'state/processes/types';
import { isCurrentProcess, getSelectedSubmission, buildSubmission } from 'state/processes/helpers';
import helpers, { LoadProgress } from 'admin/helpers';
import { ActivityHistoryItem } from 'admin/helpers/processes';
import moment from 'moment';
import { hydrate } from 'admin/helpers/activities';
import { some, none } from 'fp-ts/lib/Option';
import {
  assoc,
  clone,
  find,
  indexOf,
  lensPath,
  map,
  pathOr,
  propEq,
  set,
} from 'ramda';
import Vue from 'vue';

export default {
  progress(state: ProcessState, newValue: LoadProgress) {
    state.progress = newValue;
  },
  one(state: ProcessState, process: Process) {
    state.process = process;
    state.tasksToPreValidate = {};
  },
  clearProcess(state: ProcessState) {
    state.process = null;
    state.tasksToPreValidate = {};
  },
  setActivityHistory(state: ProcessState, history: ActivityHistoryItem[]) {
    // When a process is started, dozens of events will fire to add the
    // appropriate steps & tasks. We want to ensure that only the initial
    // 'Processes::Process::Started' event is displayed to the user. Thanksfully, these
    // events will all fire within a few milliseconds.
    const firstTimestamp = moment(history[0].metadata.timestamp);
    const cutoff = moment.duration('PT1.000S');
    state.history = history.filter((item, i) => {
      const skippableActivities = [
        'Processes::Process::TaskScheduled',
        'Processes::Process::SummarySubmissionSet',
        'Processes::Process::SubmissionAdded'
      ];
      if (skippableActivities.includes(item.type)) {
        return false;
      }

      const timeDiff = moment.duration(
        moment(item.metadata.timestamp).diff(firstTimestamp)
      );
      return i === 0 || timeDiff >= cutoff || item.type === 'Processes::Process::SummaryTaskRuleApplied';
    });

    state.historyProgress = LoadProgress.Success;

    state.history = map(a => hydrate(a, state.process), state.history);
  },
  startSubmission(state: ProcessState, { formId, formVersion }) {
    state.submissionInfo = {
      processId: 'state.process.id',
      submission: {
        createdAt: new Date(),
        updatedAt: new Date(),
        state: 'started' as SubmissionState,
        validTimestamp: null,
        values: {}
      },
      selectedSubmission: none,
      form: {
        id: formId,
        version: formVersion
      }
    };
  },
  selectSubmission(state: ProcessState, index: number) {
    state.submissionInfo.selectedSubmission = some(index);
    state.submissionInfoCache.selectedSubmission = some(index);
  },
  selectSubmissionById(state: ProcessState, id: string) {
    const index = state.submissionInfo.submissions.findIndex(
      s => s.id === id
    );
    state.submissionInfo.selectedSubmission =
      index === -1 ? none : some(index);
  },
  submissionInfo(state: ProcessState, submissionInfo: SubmissionInfo) {
    state.submissionInfo = submissionInfo;
    state.submissionInfoCache = clone(submissionInfo);
    return state;
  },
  submissionsGrid(state: ProcessState, submissions: Submission[]) {
    state.submissionsGrid = submissions;
    return state;
  },
  updatePercentComplete(state: ProcessState, { id, percent }) {
    const process = state.processes && state.processes.find(p => p.id === id);

    if (process) {
      process.percent_complete = percent;
    }

    if (isCurrentProcess(state, id)) {
      state.process.percentComplete = percent;
    }
  },
  updateSubmission(state: ProcessState, { key, value }) {
    const submission = getSelectedSubmission(state.submissionInfo);
    submission.values = assoc(key, value, submission.values);
  },
  setSubmission(state: ProcessState, { submission, index }: {submission: Submission; index: number; }) {
    Vue.set(state.submissionInfo, 'submission', submission);
    Vue.set(state.submissionInfoCache, 'submission', clone(submission));
    Vue.set(state.submissionInfo.submissions, index, submission);
    Vue.set(state.submissionInfoCache.submissions, index, clone(submission));
  },
  updateTargetTaskIds(state: ProcessState, { elementName, id, task }) {
    const submission = getSelectedSubmission(state.submissionInfo);
    submission.values[elementName].target_task_ids.push(id);
    const linkedTask = {
      task_id: task.id,
      title: task.title,
      status: task.status
    };
    submission.linkedTasks.push(linkedTask);
    return state;
  },
  updateSummary(state: ProcessState, { key, value }) {
    const summary: Submission = pathOr(
      buildSubmission(),
      ['process', 'submission'],
      state
    );
    summary.clonedValues = assoc(
      key,
      value,
      summary.clonedValues || summary.values
    );
  },
  updateTasksToPrevalidate(
    state: ProcessState,
    data: { id: Uuid; errors: TaskError; }
  ) {
    state.tasksToPreValidate = assoc(
      data.id,
      data.errors,
      state.tasksToPreValidate
    );
  },
  clearTasksToPrevalidate(state: ProcessState) {
    state.tasksToPreValidate = {};
  },
  updateTaskPreValidation(
    state: ProcessState,
    data: { taskId: Uuid; submissionId: Uuid; valid: boolean; }
  ) {
    const validationLens = lensPath([data.taskId, data.submissionId]);
    const newValue = data.valid ? null : { invalid: true };
    state.tasksToPreValidate = set(
      validationLens,
      newValue,
      state.tasksToPreValidate
    );
  },
  updateTaskUpdatedBy(state: ProcessState, task) {
    state.process.steps.forEach(s => {
      s.tasks.forEach(t => {
        if (t.id === task.id) {
          t.updatedById = task.updated_by_id;
          t.updatedByName = task.updated_by_name;
          t.lastUpdatedAt = task.last_updated_at;
          if (task.task_submission && task.task_submission.submission_ids.length > 1) {
            t.nextCheck = task.next_check || task.nextCheck;
          }
          if (task.taskSubmission && task.taskSubmission.submissionIds.length > 1) {
            t.nextCheck = task.next_check || task.nextCheck;
          }
          Vue.set(s.tasks, t.id, clone(t));
        }
      });
    });
  },
  submissionProgress(state, progress) {
    state.submissionProgress = progress;
  },
  summaryProgress(state, progress) {
    state.summaryProgress = progress;
  },
  historyProgress(state, progress) {
    state.historyProgress = progress;
  },
  submissionErrors(state, data) {
    state.submissionErrors = helpers.showErrors(data);
  },
  many(state, data) {
    state.processes = data.processes;
    return state;
  },
  updateStepModel(state, { key, value }) {
    state.stepModel[key] = value;
    state.stepProgress = '';
  },
  addStep(state, data) {
    state.process.steps.push(data.step);
  },
  stepProgress(state, progress) {
    state.stepProgress = progress;
  },
  clearStepModel(state) {
    state.stepModel = { title: '' };
  },
  stepErrors(state, data) {
    state.stepErrors = helpers.showErrors(data);
  },
  updateTaskModel(state, { key, value }) {
    state.taskModel[key] = value;
    state.taskProgress = '';
  },
  addTask(state, { stepId, data }) {
    const step = state.process.steps.find(s => s.id === stepId);
    if (step) {
      step.tasks.push(data.task);
    }
  },
  taskProgress(state, progress) {
    state.taskProgress = progress;
  },
  clearTaskModel(state) {
    state.taskModel = { title: '' };
  },
  taskErrors(state, data) {
    state.taskErrors = helpers.showErrors(data);
  },
  processSummaryUpdated(state, message) {
    state.process.submission.values = message.summary.values;
  },
  stepAdded(state, message) {
    state.process.steps.push(message.step);
  },
  taskAdded(state, message) {
    const step: ProcessStep = state.process.steps.find(s => s.id === message.stepId);
    if (step) {
      const task: ProcessTask = step.tasks.find(t => t.id === message.task.id);
      if (!task) {
        step.tasks.push(message.task);
      }
    }
  },
  taskStatusUpdated(state, message) {
    const step = state.process.steps.find(s => s.id === message.stepId);
    if (step) {
      const task = step.tasks.find(t => t.id === message.task.id);
      const statusActions = {
        stop_task: 'critical_issue',
        start_task: 'in_progress',
        complete_task: 'done',
        invalidate_task: 'not_required'
      };
      task.status = statusActions[message.status];
    }
  },
  processStatusUpdated(state, updated_properties) {
    state.process = { ...state.process,...updated_properties }
  },
  submissionCopied(state, message) {
    const step = state.process.steps.find((s) => s.id === message.stepId);
    if (step) {
      const task = step.tasks.find((t) => t.id === message.task.id);
      if (task) {
        task.submissionsCount = message.submissionsCount;
      }
    }
  },
  taskNextCheckUpdated(state, message) {
    const step = state.process.steps.find(s => s.id === message.stepId);
    if (step) {
      const task = step.tasks.find(t => t.id === message.task.id);
      task.nextCheck =
        message.task.next_check || message.msg_options.next_check;
    }
  },
  errors(state, data) {
    state.errors = helpers.showErrors(data);
    return state.errors;
  },
  sendPdfProgress(state, progress) {
    state.sendPdfProgress = progress;
  },
  sendSubmissionPdfProgress(state, progress) {
    state.sendSubmissionPdfProgress = progress;
  },
  clearSendPdfModel(state) {
    state.sendPdfModel = { recipients: [] };
  },
  sendPdfErrors(state, data) {
    state.sendPdfErrors = helpers.showErrors(data);
  },
  sendSubmissionPdfErrors(state, data) {
    state.sendSubmissionPdfErrors = helpers.showErrors(data);
  },
  updateSendPdfModel(state, { key, value }) {
    state.sendPdfModel[key] = value;
    state.sendPdfProgress = LoadProgress.Init;
  },
  clearSubmissionInfo(state) {
    state.submissionInfo = {
      selectedSubmission: none
    };
  },
  updateProcessTask(state, { task, step }) {
    const steps = state.process.steps;
    const stepIndex = indexOf(find(propEq('id', step), steps), steps);
    const taskIndex = indexOf(
      find(propEq('id', task.id), steps[stepIndex].tasks),
      steps[stepIndex].tasks
    );

    state.process.steps[stepIndex].tasks[taskIndex] = task;
    if (!state.process.procedureForms[task.procedureFormId]) {
      state.process.procedureForms.push({
        form: task.form,
        procedureFormId: task.procedureFormId
      });
    }
  },
}
