import helpers, { LoadProgress } from 'admin/helpers';
import { getService } from 'admin/components/forms/elements/formFieldServices';
import { clone, equals } from 'ramda';
import uuid from 'uuid';
import Vue from 'vue';

export interface ErrorsByIndex {
  [index: number]: string[];
}

const FormBuilderStore = {
  namespaced: true,
  state: {
    form: {},
    formIsSubmittable: true,
    fields: [],
    tabIndex: 0,
    errors: {},
    errorsByIndex: {},
    progress: LoadProgress.Init,
    saveProgress: LoadProgress.Init,
    selectedIndex: -1,
    savedForm: {}
  },
  mutations: {
    disableFormSubmit(state) {
      state.formIsSubmittable = false;
    },
    enableFormSubmit(state) {
      state.formIsSubmittable = true;
    },
    one(state, data) {
      state.errors = {};
      state.form = data.form;
      state.fields = data.fields;
      state.savedForm = clone(data.form);
      state.selectedIndex = -1;
      return state;
    },
    progress(state, step: LoadProgress) {
      state.progress = step;
      return state.progress;
    },
    saveProgress(state, step: LoadProgress) {
      state.saveProgress = step;
      return state.saveProgress;
    },
    errors(state, data) {
      state.errors = helpers.showErrors(data);
      return state.errors;
    },
    errorsByIndex(state, errorsByIndex: ErrorsByIndex) {
      state.errorsByIndex = errorsByIndex;
    },
    addFormElement(state, { field }) {
      const service = getService(field.field);
      state.form.formElements.push(service.getEmptyElement(uuid.v4(), field.text || null, isNaN(field.live_process_column_index) ? null : field.live_process_column_index));
    },
    removeFormElement(state, i) {
      if (i > -1) {
        state.form.formElements.splice(i, 1);
      }
    },
    selectElement(state, i) {
      state.selectedIndex = i;
    },
    updateModel(state, { key, value }) {
      state.form[key] = value;
    },
    updateSetting(state, { index, key, setting }) {
      Vue.set(state.form.formElements[index], key, setting)
    },
    setTabIndex(state, index) {
      state.tabIndex = index;
    },
    updateElementOrder(state, formElements) {
      state.form.formElements = formElements;
    },
    setFormElementError(state, index) {
      state.form.formElements = state.form.formElements.map((element, i) => {
        if (i === index) {
          element.errored = true;
        }
        return element;
      });
    },
    clearFormElementErrors(state) {
      state.form.formElements = state.form.formElements.map((element) => {
        element.errored = false;
        return element;
      });
    },
    clearState(state) {
      state.form = {};
      state.formIsSubmittable = true;
      state.fields = [];
      state.tabIndex = 0;
      state.errors = {};
      state.errorsByIndex = {};
      state.progress = LoadProgress.Init;
      state.selectedIndex = -1;
      state.savedForm = {};
    }
  },
  getters: {
    formIsModified: (state) => !equals(state.form, state.savedForm)
  },
  actions: {
    new(context) {
      context.commit('progress', LoadProgress.Loading);
      return new Promise((resolve) => {
        $.ajax({
          url: 'admin/forms/new',
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve();
            context.commit('progress', LoadProgress.Success);
          }
        });
      });
    },
    create(context, form) {
      context.commit('saveProgress', LoadProgress.Loading);
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/forms',
          type: 'post',
          data: { form },
          success: (data) => {
            context.commit('saveProgress', LoadProgress.Success);
            context.commit('errorsByIndex', {});
            resolve(data);
          },
          error: (data) => {
            context.commit('saveProgress', LoadProgress.Failed);
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    get(context, { id, version }) {
      context.commit('progress', LoadProgress.Loading);

      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/forms/${id}/${version}`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            context.commit('progress', LoadProgress.Success);
            resolve(data.form);
          },
          error: reject
        });
      });
    },
    update(context, form) {
      context.commit('saveProgress', LoadProgress.Loading);
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/forms/${form.form_id}`,
          type: 'put',
          data: { form },
          success: (data) => {
            context.commit('saveProgress', LoadProgress.Success);
            context.commit('errorsByIndex', {});
            resolve(data);
          },
          error: (err) => {
            context.commit('saveProgress', LoadProgress.Failed);
            context.commit('errors', err);
            reject(err);
          }
        });
      });
    },
    destroy(_context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/forms/${id}`,
          type: 'delete',
          success: (data) => {
            resolve(data);
          },
          error: (data) => {
            reject(data);
          }
        });
      });
    }
  }
};

export default FormBuilderStore;
