import actions from 'state/proposal_reviews/actions';
import mutations from 'state/proposal_reviews/mutations';
import state from 'state/proposal_reviews/state';
import getters from 'state/proposal_reviews/getters';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
