import { is, propOr } from 'ramda';
import { getCookie } from 'helpers/cookies';
import { SessionState } from 'state/session/state';

export default {
  hasActiveSetup: (state: SessionState) =>
    is(Function, state.hideWarningModal) &&
    is(Function, state.hideAlertModal) &&
    is(Function, state.showWarningModal) &&
    is(Function, state.showAlertModal),

  nextRoutePath: () => {
    const nextRoutePath = getCookie('nextRoutePath');

    // Parse and return `path` it if it exists, and...
    // ...if no `path` is set, send them to the default route.
    return propOr('/processes', 'path', nextRoutePath);
  }
};
