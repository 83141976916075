import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface DoubleBlind extends FormElement {
  type: 'DoubleBlind';
  name: string;
  label: string;
  label_2: string;
  required: boolean;
  input_type: string;
}

const service: FormBuilderElementService<DoubleBlind> = {
  getEmptyElement: (id: string): DoubleBlind => {
    return {
      id,
      name: '',
      label: 'Double Blind entry one',
      label_2: 'Double Blind entry two',
      type: 'DoubleBlind',
      required: false,
      input_type: 'number'
    };
  }
};

export default service;
