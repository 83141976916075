import { mergeRight } from 'ramda';
import uuid from 'uuid';
import { ToastMessage } from './types';

const actions = {
  toast({ commit }, newValues: ToastMessage) {
    const defaultValues: ToastMessage = {
      id: uuid.v4(),
      toast_type: null,
      color: 'info',
      title: '',
      text: '',
      duration: 6000
    };
    const message = mergeRight(defaultValues, newValues);

    commit('TOAST', message);
    if (message.duration) {
      setTimeout(() => commit('REMOVE', message.id), message.duration);
    }
  },

  remove({ commit }, id: string) {
    commit('REMOVE', id);
  }
};

export default actions;
