import { BaseState, buildState, baseAssetMutations } from 'admin/state/base_store';

interface ShiftState extends BaseState {
  shift: Shift;
  shifts: Shift[];
}

interface OneShift {
  shift: Shift;
}

interface PaginatedShifts extends PaginatedResources {
  shifts: Shift[];
}

const ShiftStore = {
  namespaced: true,
  state: buildState<ShiftState>({
    shifts: [],
    shift: { status: 'published' }
  }),
  mutations: baseAssetMutations<ShiftState, OneShift, PaginatedShifts>('shift'),
  actions: {
    index(context, query) {
      const opts = {};
      Object.keys(query).forEach((prop) => {
        opts[`q[${prop}_cont]`] = query[prop];
      });
      $.ajax({
        url: 'admin/shifts',
        type: 'get',
        data: opts,
        success: (data) => {
          context.commit('many', data);
        }
      });
    },
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/shifts',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    autocomplete(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/shifts/autocomplete',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data.shifts);
          },
          error: reject
        });
      });
    },
    new(context) {
      $.ajax({
        url: 'admin/shifts/new',
        type: 'get',
        success: (data) => {
          context.commit('one', data);
        }
      });
    },
    create(context, form) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/shifts',
          type: 'post',
          data: form,
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    edit(context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/shifts/${id}/edit`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    update(context, shift) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/shifts/${shift.id}`,
          type: 'put',
          data: {
            shift
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    destroy(_context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/shifts/${id}`,
          type: 'delete',
          success: (data) => {
            resolve(data);
          },
          error: (data) => {
            reject(data);
          }
        });
      });
    }
  }
};

export default ShiftStore;
