import { LoadProgress } from 'admin/helpers';
import { TargetVersionIds } from 'state/versions/types'
import { Proposal } from 'state/versions/types';
import PromiseGuard from 'helpers/promiseGuard';

export default {
  async published({ commit }, query) {
    const publishedPromiseQuery = new Promise((resolve, reject) => {
      $.ajax({
        url: 'documents/versions/paginated_published',
        type: 'get',
        data: query,
        success: data => {
          resolve(data);
        },
        error: reject
      });
    });

    if ((this) && !(this.promiseGuard)) { this.promiseGuard = new PromiseGuard(); }
    const publishedResponse = (this)
      ? await this.promiseGuard.deRace(publishedPromiseQuery)
      : await publishedPromiseQuery;

    commit('CLEAR_VERSIONS');
    commit('CLEAR_FOLDERS');
    commit('SET_VERSIONS', publishedResponse);
    commit('SET_FOLDERS', publishedResponse);

    return publishedResponse
  },

  async drafts({ commit }, query) {
    const draftPromiseQuery = new Promise((resolve, reject) => {
      $.ajax({
        url: 'documents/versions/paginated_drafts',
        type: 'get',
        data: query,
        success: data => {
          resolve(data);
        },
        error: reject
      });
    });

    if ((this) && !(this.promiseGuard)) { this.promiseGuard = new PromiseGuard(); }
    const draftResponse = (this)
      ? await this.promiseGuard.deRace(draftPromiseQuery)
      : await draftPromiseQuery;

    commit('CLEAR_DRAFT_VERSIONS');
    commit('CLEAR_FOLDERS');
    commit('SET_DRAFT_VERSIONS', draftResponse);
    commit('SET_FOLDERS', draftResponse);

    return draftResponse;
  },

  async trashed({ commit }, query) {
    const trashedPromiseQuery = new Promise((resolve, reject) => {
      $.ajax({
        url: 'documents/versions/paginated_trashed',
        type: 'get',
        data: query,
        success: data => {
          resolve(data);
        },
        error: reject
      });
    })

    if ((this) && !(this.promiseGuard)) { this.promiseGuard = new PromiseGuard(); }
    const trashedResponse = (this)
      ? await this.promiseGuard.deRace(trashedPromiseQuery)
      : await trashedPromiseQuery;

    commit('CLEAR_TRASH_VERSIONS');
    commit('CLEAR_FOLDERS');
    commit('SET_TRASHED_VERSIONS', trashedResponse);
    commit('SET_FOLDERS', trashedResponse);

    return trashedResponse;
  },

  tags(_, query) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'documents/versions/tags',
        type: 'get',
        data: query,
        success: resolve,
        error: reject
      });
    });
  },

  inDocument({ commit }, [documentId, query]) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${documentId}/versions`,
        type: 'get',
        data: query,
        success: data => {
          commit('CLEAR_VERSIONS');
          commit('CLEAR_FOLDERS');
          commit('SET_VERSIONS', data);
          resolve(data);
        },
        error: reject
      });
    });
  },

  edit({ commit }, target_ids: TargetVersionIds) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${target_ids.documentId}/versions/${target_ids.versionId}/edit`,
        type: 'get',
        success: data => {
          commit('SET_VERSION', data);
          resolve(data);
        },
        error: reject
      });
    });
  },

  getLatestDraftVersion({ commit }, data: { documentId: string; versionId: string; }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${data.documentId}/versions/${data.versionId}/draft`,
        type: 'get',
        success: data => {
          commit('SET_VERSION', data);
          resolve(data);
        },
        error: reject
      });
    });
  },

  getLatestVersion({ commit }, documentId: string) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${documentId}/latest_version`,
        type: 'get',
        success: data => {
          commit('SET_VERSION', data);
          resolve(data.version);
        },
        error: reject
      });
    });
  },

  addVersion({ commit }, { documentId, version }) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading);

    
    return $.ajax({
      url: `documents/documents/${documentId}/versions`,
      type: 'post',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({ version }),
      success: data => {
        commit('UPDATE_PROGRESS', LoadProgress.Success);
        Promise.resolve(data.versions);
      },
      error: err => {
        commit('UPDATE_PROGRESS', LoadProgress.Failed);
        Promise.reject(err);
      }
    });
  },

  duplicateVersion({ commit }, documentId: Uuid) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading);

    return $.ajax({
      url: `documents/documents/${documentId}/duplicate_version`,
      type: 'post',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({ documentId }),
      success: data => {
        commit('UPDATE_PROGRESS', LoadProgress.Success);
        Promise.resolve(data.versions);
      },
      error: err => {
        commit('UPDATE_PROGRESS', LoadProgress.Failed);
        Promise.reject(err);
      }
    });
  },

  addProposal(_, data: { versionId: Uuid; proposal: Proposal; }) {
    const json = {
      title: data.proposal.title,
      fileupload: {
        value: data.proposal.fileupload.value
      }
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/versions/${data.versionId}/add_proposal`,
        type: 'post',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({ proposal: json }),
        success: resolve,
        error: reject
      });
    });
  },

  archive({ dispatch }, version: { versionId: Uuid; status: string; }) {
    const toast = version.status === 'published'
      ? { title: 'Document version archived', text: 'Document version archived. The document is now "hidden" from staff view.' }
      : { title: 'Document version discarded', text: 'Document version discarded.' };

    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/versions/${version.versionId}/archive`,
        type: 'post',
        success: data => {
          dispatch('ToastStore/toast', toast, { root: true });
          resolve(data);
        },
        error: err => {
          dispatch('ToastStore/toast', {
            title: 'Error',
            text: 'Please try again or contact support',
            color: 'danger'
          }, { root: true });
          reject(err);
        }
      });
    });
  },

  refreshVersionInById({ commit }, target_ids: TargetVersionIds) {
    return $.ajax({
      url: `documents/documents/${target_ids.documentId}/versions/${target_ids.versionId}/edit`,
      type: 'get',
      success: version => {
        commit('REFRESH_VERSION_IN_BY_ID', version);
        Promise.resolve(version);
      },
      error: error => {
        return Promise.reject(error);
      }
    });
  },

  refreshVersionInDraftsById({ commit }, target_ids: TargetVersionIds) {
    return $.ajax({
      url: `documents/documents/${target_ids.documentId}/versions/${target_ids.versionId}/edit`,
      type: 'get',
      success: version => {
        commit('REFRESH_VERSION_IN_DRAFTS_BY_ID', version);
        Promise.resolve(version);
      },
      error: error => {
        return Promise.reject(error);
      }
    });
  },

  refreshVersionInTrashedbyId() {
    // TODO:
  },
};
