import { LoadProgress } from 'admin/helpers';
import { DocumentSubmission, DocumentsState } from 'state/documents/types';

export default {
  ids: [],
  byId: {},
  draftIds: [],
  draftsById: {},
  trashedIds: [],
  trashedById: {},
  currentId: null,
  newDocument: {
    folders: [],
    tags: [],
    reviews: [],
    enforceReviewOrder: false
  },
  editDocument: {
    folders: [],
    extension: '',
    expiry: ''
  },
  restoreDocument: {
    folders: [],
    extension: '',
    expiry: ''
  },
  editSidebarDocument: {
    folders: [],
    extension: '',
    expiry: '',
    cancelReviews: []
  },
  changeLocationDocument: {
    folders: []
  } as DocumentSubmission,
  progress: LoadProgress.Init
} as DocumentsState;
