import { FolderState } from './types';

export default {
  modelFolder: {
    title: ''
  },
  folderToTrash: null,
  folderToRestore: null,
  folderToHide: null,
  folderToEdit: null
} as FolderState;
