import actions from 'state/documents/actions';
import getters from 'state/documents/getters';
import state from 'state/documents/state';
import mutations from 'state/documents/mutations';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
