import Vue from 'vue';
import { LoadProgress } from 'admin/helpers';
import { Document, DocumentsState } from 'state/documents/types';
import { Version } from 'state/versions/types';
import { mergeDeepRight, clone } from 'ramda';

export const initDocument = (
  state: DocumentsState,
  version: Version
): { documentId: Uuid; document: Document; } => {
  const { documentId, versionId } = version;
  const document: Document = state.byId[documentId]
    ? state.byId[documentId]
    : {};

  document.versionIds = document.versionIds
    ? [...document.versionIds, versionId]
    : [versionId];
  document.publishedId = versionId;

  return { documentId, document };
};

export default {
  SET_PUBLISHED_VERSION(state: DocumentsState, version: Version) {
    const { documentId, document } = initDocument(state, version);
    const ids: Uuid[] = Array.from(new Set([...state.ids, documentId]));

    Vue.set(state.byId, documentId, document);
    state.ids = ids;
  },

  SET_DRAFT_VERSION(state: DocumentsState, version: Version) {
    const { documentId, document } = initDocument(state, version);
    const ids: Uuid[] = Array.from(new Set([...state.draftIds, documentId]));

    Vue.set(state.draftsById, documentId, document);
    state.draftIds = ids;
  },

  SET_TRASHED_VERSION(state: DocumentsState, version: Version) {
    const { documentId, document } = initDocument(state, version);
    const ids: Uuid[] = Array.from(new Set([...state.trashedIds, documentId]));

    Vue.set(state.trashedById, documentId, document);
    state.trashedIds = ids;
  },

  UPDATE_NEW_DOCUMENT(state: DocumentsState, { key, value }) {
    Vue.set(state.newDocument, key, value);
  },

  CLEAR_NEW_DOCUMENT(state: DocumentsState) {
    state.newDocument = {
      folders: [],
      extension: '',
      tags: [],
      reviews: [],
      enforceReviewOrder: false
    };
  },

  UPDATE_EDIT_DOCUMENT(state: DocumentsState, { key, value }) {
    state.editDocument[key] = value;
  },

  UPDATE_EDIT_SIDEBAR_DOCUMENT(state: DocumentsState, { key, value }) {
    state.editSidebarDocument[key] = value;
  },
  
  UPDATE_RESTORE_DOCUMENT(state: DocumentsState, { key, value }) {
    state.restoreDocument[key] = value;
  },

  UPDATE_CHANGE_LOCATION_DOCUMENT(state: DocumentsState, { key, value }) {
    state.changeLocationDocument[key] = value;
  },

  CLEAR_EDIT_DOCUMENT(state: DocumentsState) {
    state.editDocument = {
      folders: [],
      extension: '',
      tags: [],
      reviews: [],
      enforceReviewOrder: false
    };
  },

  SET_CURRENT_ID(state: DocumentsState, id: Uuid) {
    state.currentId = id;
  },

  UPDATE_PROGRESS(state: DocumentsState, newValue: LoadProgress) {
    state.progress = newValue;
  },

  SET_DOCUMENT(state: DocumentsState, data) {
    state.editDocument = mergeDeepRight({ folders: [] }, data);
  },

  SET_EDIT_SIDEBAR_DOCUMENT(state: DocumentsState, data) {
    // TODO: clean up
    data.reviews = data.draftProposal.proposalReviews;
    data.fileupload = data.draftProposal.fileupload;
    data.enforceReviewOrder = data.draftProposal.enforceReviewOrder;
    data.cancelReviews = [];
    state.editSidebarDocument = data;
  },
  
  SET_DOCUMENT_TO_RESTORE(state: DocumentsState, data) {
    const restoreDocument = clone(data);
    restoreDocument.folders = restoreDocument.folders.filter((f) => f.status === 'active');
    restoreDocument.status = undefined;
    state.restoreDocument = mergeDeepRight({ folders: [] }, restoreDocument);
  },

  SET_CHANGE_LOCATION_DOCUMENT(state: DocumentsState, data) {
    state.changeLocationDocument = mergeDeepRight({ folders: [] }, data);
  },

  REFRESH_DOCUMENT_IN_BY_ID(state: DocumentsState, document: any) {
    state.byId[document.documentId] = document;
  },
};
