import { get, remove, setCookie as set } from 'tiny-cookie';

export const getCookie = (name: string) => {
  const payload = get(name);

  try {
    return JSON.parse(payload);
  } catch (e) {
    return payload;
  }
};

export const setCookie = (name: string, payload: object) => {
  return set(name, payload, JSON.stringify, { samesite: 'Strict' });
};

export const removeCookie = (name: string) => {
  return remove(name, { samesite: 'Strict' });
};
