import moment from 'moment';
import { Option, fold, fromNullable } from 'fp-ts/lib/Option';
import { all, includes, propSatisfies } from 'ramda';

export interface ActivityHistoryItem {
  id: string;
  type: string;
  data: {[key: string]: any; };
  avatar?: any;
  metadata: {
    timestamp: string;
    [key: string]: string;
  };
}

const calculateProcessRunTime = (process: Process, now = new Date()) => {
  const started = moment(process.createdAt);
  const end = moment(now);
  const duration = moment.duration( end.diff(started) );
  const days = Math.floor(duration.asDays());
  const dayPart = days > 0 ? `${days}d` : '';
  return `${dayPart} ${duration.hours()}h ${duration.minutes()}m`;
};

const getStep = (process: Process, stepId: string): Option<ProcessStep> =>
  fromNullable(process.steps.find((s) => s.id === stepId));

const getTask = (process: Process, stepId: string, taskId: string): Option<ProcessTask> => {
  const stepO = getStep(process, stepId);
  const task = fold<ProcessStep, ProcessTask>(
    () => null,
    (step) => step.tasks.find((t) => t.id === taskId)
  )(stepO);
  return fromNullable(task);
};

// ProcessTask -> boolean
const isComplete = propSatisfies<string, ProcessTask>(
  (status: string) => includes(status, ['done', 'not_required']),
  'status'
);

// ProcessStep[] -> boolean
const allTasksComplete = all<ProcessStep>(
  (s) => all(isComplete)(s.tasks || [])
);

export { calculateProcessRunTime, allTasksComplete, getStep, getTask };
