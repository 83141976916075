import helpers, { LoadProgress } from 'admin/helpers';

export interface BaseState {
  errors: any;
  progress: LoadProgress;
  pagination: Pagination;
}

export const buildState = <T extends BaseState>(state: any): T => {
  return Object.assign({
    errors: {},
    progress: LoadProgress.Init,
    pagination: {}
  }, state);
};

export const clearModel = <T extends BaseState>(modelKey: string) => (state: T) => {
  state[modelKey] = { status: 'published' };
};

export const one = <T extends BaseState, O>(key: string) =>
  (state: T, data: O): T => {
    state.errors = {};
    state[key] = data[key];
    return state;
  };

export const many = <T extends BaseState, P extends PaginatedResources>(key: string) =>
  (state: T, data: P): T => {
    state[key] = data[key];
    state.pagination = data.pagination;
    return state;
  };

export const progress = <T extends BaseState>(state: T, step: LoadProgress) => {
  state.progress = step;
  return state.progress;
};

export const updateModel = <T extends BaseState>(modelKey: string) =>
  (state: T, { key, value }) => {
    state[modelKey][key] = value;
    state.progress = LoadProgress.Init;
  };

export const errors = <T extends BaseState>(state: T, data: any) => {
  state.errors = helpers.showErrors(data);
  return state.errors;
};

export const baseAssetMutations =
<T extends BaseState, O, P extends PaginatedResources>(keySingular: string, keyPlural = `${keySingular}s`) => {
  return {
    one: one<T, O>(keySingular),
    many: many<T, P>(keyPlural),
    progress,
    errors,
    updateModel: updateModel<T>(keySingular),
    clearModel: clearModel<T>(keySingular)
  };
};
