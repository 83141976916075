































import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
  props: {
    id: String,
    title: String,
    hideHeaderClose: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'large',
      validator: (value): boolean => {
        return ['xsmall', 'small', 'medium', 'large', 'xlarge', 'huge'].indexOf(value) !== -1;
      }
    },
    fixedHeight: Boolean,
    cover: Boolean
  },
  data() {
    return {
      scrollableSelector: `#${this.id}`,
      scrollable: null
    };
  },
  methods: {
    onShow() {
      const getScrollable = setInterval(() => {
        this.scrollable = document.querySelector(this.scrollableSelector);
        if (this.scrollable) {
          disableBodyScroll(this.scrollable, {
            allowTouchMove: el => el.tagName === 'LI'
          });
          this.$emit('show');
          clearInterval(getScrollable);
        }
      }, 10);
    },
    onHide() {
      enableBodyScroll(this.scrollable);
    },
    onHidden() {
      this.$emit('hidden');
    },
  }
};
