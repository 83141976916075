import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface MachineLookup extends FormElement {
  type: 'MachineLookup';
  name: string;
  label: string;
  placeholder?: string;
  required: boolean;
}

const service: FormBuilderElementService<MachineLookup> = {
  getEmptyElement: (id: string): MachineLookup => {
    return {
      id,
      name: '',
      label: 'Machine Lookup',
      type: 'MachineLookup',
      placeholder: 'Please select one…',
      required: false
    };
  }
};

export default service;
