import { DirectUpload } from 'activestorage';
import {
  append, assoc, cond, equals, has, ifElse, is, lensProp,
  over, reduce, tryCatch
} from 'ramda';
import { Store } from 'vuex';
import { ErrorsByIndex } from 'admin/state/stores/form_builder_store';

interface QueryParams { [key: string]: any; }

export interface FieldData {
  query?: QueryParams;
  name?: string;
  searchColumns?: string[];
}

interface RailsErrors {
  [key: string]: Array<string & {sort_order: number; }>;
}

interface ErrorResponse {
  responseJSON: {
    errors: RailsErrors;
  };
}

export enum UploadProgress {
  Ready = 'ready',
  Uploading = 'uploading',
  Complete = 'complete',
  Error = 'error'
}

export interface WxFile {
  filename: string;
  signed_id?: string;
  url: string;
  image_url?: string;
  status: UploadProgress;
  uploadedAt: string;
}

export enum LoadProgress {
  Failed = 'failed',
  Init = '',
  Loading = 'loading',
  NotFound = 'notFound',
  Success = 'success',
}

export enum EmployeeType {
  Admin = 'admin',
  Supervisor = 'supervisor',
  Staff = 'staff',
  ITAdmin = 'it_admin',
}

export enum TaskMoveDirection {
  Up = -1,
  Down = 1,
}

const buildQueryFromFieldData = (fieldData: FieldData): QueryParams => {
  if (fieldData.query) {
    return fieldData.query;
  }

  if (! fieldData.name || ! fieldData.searchColumns) {
    return {};
  }

  const value = `%${fieldData.name}%`;
  return reduce<string, QueryParams>(
    (query, prop) => assoc(`q[${prop}]`, value, query),
    {}, fieldData.searchColumns
  );
};

export const camelCaseToSnakeCase = (camel: string) =>
  camel.replace(/(?:^|\.?)([A-Z])/g, (_x, y) => '_' + y.toLowerCase())
    .replace(/^_/, '');

// TODO Should update the paramater type here
// The intent is to change objects with keys in camelCase to snake_case
export const camelToSnakeKeys = (obj: any) => {
  Object.keys(obj).forEach(oldKey => {
    const new_key = camelCaseToSnakeCase(oldKey)
    if (new_key !== oldKey) {
      delete Object.assign(obj, { [new_key]: obj[oldKey] })[oldKey];
    }
  });
  return obj;
}

export const coerceToArray = (x: any): any[] => Array.isArray(x) ? x : [x];

export const employeeTypeToString = (type: EmployeeType): string =>
  cond([
    [equals(EmployeeType.Admin),      () => 'Admin'],
    [equals(EmployeeType.ITAdmin),    () => 'IT Admin'],
    [equals(EmployeeType.Staff),      () => 'Staff'],
    [equals(EmployeeType.Supervisor), () => 'Supervisor'],
  ])(type);

const showErrors = (data: ErrorResponse): RailsErrors => {
  const errors: RailsErrors = {};
  const messages = (data.responseJSON ? data.responseJSON.errors : data) || {};

  return Object.assign(errors, messages);
};

const setFormElementErrors = (store, errors) => {
  if (is(Array, errors)) {
    errors
      .filter(((err) => err.sort_order))
      .forEach((err) => store.commit('FormStore/setFormElementError', err.sort_order));
  }
};
const getErrorsByIndex = (elementIndex, text, errorsByIndex) =>
  ifElse(
    has(elementIndex),
    over(lensProp(elementIndex), append(text)),
    assoc(elementIndex, [text])
  )(errorsByIndex);
const numberPattern = /\d+/g;
const getElementIndex = (k: string) => parseInt(k.split('.').shift().match(numberPattern).pop(), 10);
const tryGetElementIndex = tryCatch(getElementIndex, () => null);

const setEachError = (store: Store<any>, e: ErrorResponse) => {
  let errorsByIndex: ErrorsByIndex = {};
  const titles = [];
  const warnings = [];

  Object.keys(e.responseJSON.errors).forEach((fullKey) => {
    if (e.responseJSON.errors[fullKey]) {
      let title = 'Error';
      let text = '';

      const errors = e.responseJSON.errors[fullKey];
      const key = fullKey.split('.').pop();
      const elementIndex = tryGetElementIndex(fullKey);

      switch (key) {
      case 'name':
        text = errors[0];
        break;
      case 'content':
        text = 'Form element(s) content cannot be blank';
        break;
      case 'operands':
        title = 'Could not save';
        /* tslint:disable:max-line-length */
        text = 'A Calculation field in this form is missing a required setting. Please edit the field to correct the issue.';
        /* tslint:enable:max-line-length */
        break;
      case 'label':
        title = 'Could not save';
        /* tslint:disable:max-line-length */
        text = 'A field in this form is missing a label. Please edit the field to correct the issue.';
        /* tslint:enable:max-line-length */
        break;
      case 'places':
        title = 'Could not save';
        text = 'A Number or Number Check field has an invalid "Decimal Places" setting. Only integer values between 0 and 5 are permitted.'
        break;
      case 'base':
        title = 'Could not save';
        text = errors[0];
        break;
      default:
        text = 'Unknown error. Please contact support.';
      }

      titles.push(title);
      warnings.push(text);

      setFormElementErrors(store, errors);
      errorsByIndex = getErrorsByIndex(elementIndex, text, errorsByIndex);
    }
  });

  return { titles, warnings, errorsByIndex };
};

const setErrors = (store: Store<any>, e: ErrorResponse): ErrorsByIndex => {
  const { titles, warnings, errorsByIndex } = setEachError(store, e);

  warnings.forEach((text, i) => {
    const title = titles[i];
    store.dispatch('ToastStore/toast', {
      text,
      title,
      color: 'danger'
    });
    store.commit('FormBuilderStore/errorsByIndex', errorsByIndex);
  });

  return errorsByIndex;
};

const uploadFile = (file: File, fileName: string = null) => {
  const url = '/rails/active_storage/direct_uploads';
  const upload = new DirectUpload(file, url);

  if (fileName) {
    upload.file.name = fileName;
  }

  return new Promise<WxFile>((resolve, reject) => {
    upload.create((error: string, blob: {signed_id: string; }) => {
      if (error) {
        reject(error);
      } else {
        $.ajax({
          url: `uploads/${blob.signed_id}`,
          type: 'get',
          success: (res) => {
            res.file.status = UploadProgress.Complete;
            resolve(res.file);
          },
          error: reject
        });
      }
    });
  });
};

export const isTimeoutOrOffline = (status: number) => [-1, 0, 408, 504, 598, 599].includes(status);
export const displayOfflineMsg = (context: any) => {
  context.dispatch('ToastStore/toast', {
    title: 'Submission failed',
    text: 'Please try again, check your internet connection or contact support.',
    color: 'danger'
  }, { root: true });
};

export const isResourceLocked = (status: number) => status === 423;
export const displayResourceLockedMsg = (context: any) => {
  context.dispatch('ToastStore/toast', {
    title: 'Update failed',
    text: 'That resource is in use, please try again later.',
    color: 'danger'
  }, { root: true });
};

export const displayForbiddenMsg = (context: any) => {
  context.dispatch('ToastStore/toast', {
    title: 'Update failed',
    text: 'You are not authorized to update this task',
    color: 'danger'
  }, { root: true });
};

export default {
  buildQueryFromFieldData, showErrors, uploadFile,
  setErrors, displayForbiddenMsg
};
