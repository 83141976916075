import { LoadProgress } from 'admin/helpers';
import { mergeRight } from 'ramda';

export interface ProcedureState {
  status: LoadProgress;
  procedure: Procedure;
  procedures: Procedure[];
}

const ProcedureStore = {
  namespaced: true,
  state: {
    status: LoadProgress.Init,
    procedure: null,
    procedures: [],
    versions: null,
    task: {
      step: -1,
      title: ''
    }
  } as ProcedureState,
  mutations: {
    updateStatus(state: ProcedureState, newValue: LoadProgress) {
      state.status = newValue;
    },
    many(state: ProcedureState, procedures: Procedure[]) {
      state.procedures = procedures;
    },
    one(state: ProcedureState, procedure: Procedure) {
      state.procedure = procedure;
    },
    updateTask(state, props) {
      state.task = mergeRight(state.task, props);
    },
    setIndex(state, i) {
      state.task.step = i;
    },
    clearTask(state) {
      state.task = {
        step: -1,
        title: ''
      };
    },
    versions(state, versions) {
      state.versions = versions;
    }
  },
  actions: {
    index(context, query) {
      query.order_by = query.order_by || 'title';
      query.order_desc = query.order_desc || 'ASC';
      context.commit('updateStatus', LoadProgress.Loading);
      $.ajax({
        url: 'procedures',
        type: 'get',
        data: query,
        success: (data) => {
          if (data.errors) {
            throw new Error(data.errors.map((e: any) => e.message).join(', '));
          }
          context.commit('many', data.procedures);
          context.commit('updateStatus', LoadProgress.Success);
        }
      });
    },
    get(context, { id, version }) {
      context.commit('updateStatus', LoadProgress.Loading);

      return new Promise((resolve, reject) => {
        $.ajax({
          url: `procedures/${id}`,
          type: 'get',
          data: { version },
          success: (data) => {
            context.commit('one', data.procedure);
            context.commit('updateStatus', LoadProgress.Success);
            resolve(data.procedure);
          },
          error: reject
        });
      });
    },
    getVersions(context, { id }) {
      context.commit('updateStatus', LoadProgress.Loading);
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `procedures/versions/${id}`,
          type: 'get',
          success: (data) => {
            context.commit('updateStatus', LoadProgress.Success);
            context.commit('versions', data.versions);
            resolve(data.procedure);
          },
          error: reject
        });
      });
    },
    getSummaryForm(context, { id, version }) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `procedures/${id}/${version}/summary_form`,
          type: 'get',
          success: (data) => {
            context.commit('updateStatus', LoadProgress.Success);
            // context.commit('versions', data.versions);
            resolve(data.form);
          },
          error: reject
        });
      });
    }
  }
};

export default ProcedureStore;
