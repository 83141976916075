export default {
  setTaskRule(state, taskRule) {
    state.taskRule = taskRule;
  },
  updateTaskRule(state, { key, value }) {
    state.taskRule = { ...state.taskRule, [key]: value };
  },
  clearTaskRule(state) {
    state.taskRule = {}
  },
  setTaskRuleForm(state, { form, fields }) {
    state.taskRuleForm = form;
    state.taskRuleFormFields = fields;
  },
  setTaskRuleTask(state, task) {
    state.taskRuleTask = task;
  }
};
