import moment from 'moment-timezone';
import { LOCAL_TIMEZONE } from 'helpers/time';

export enum ExpiryStatus {
  ExpiresSoon = 'Expires soon',
  Expired = 'Expired',
  NotExpired = 'Expiry'
}

export const expiryColor = (expiryBadge: string): string => {
  if (expiryBadge === ExpiryStatus.ExpiresSoon) {
    return 'u-color-warning';
  } else if (expiryBadge === ExpiryStatus.Expired) {
    return 'u-color-danger';
  }
  return ExpiryStatus.NotExpired;
};

export const expiryBadge = (expiry: string): string => {
  if (expiry === null) {
    return '';
  }
  const today = moment().tz(LOCAL_TIMEZONE).startOf('day');
  const expiresSoonDate = moment().tz(LOCAL_TIMEZONE).add(30, 'days').startOf('day');
  const expiryDate = moment(expiry).tz(LOCAL_TIMEZONE);

  if (expiryDate.isBefore(today, 'day')) {
    return ExpiryStatus.Expired;
  } else if (expiryDate.isBefore(expiresSoonDate, 'day')) {
    return ExpiryStatus.ExpiresSoon;
  } else {
    return ExpiryStatus.NotExpired;
  }
};
