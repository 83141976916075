import { ExtendedTask } from './types';

export interface RequiredTask {
  id: string;
  title: string;
  updatedAt: Date;
}

export interface TaskState {
  actionRequiredCount: number;
  requiredTasks: RequiredTask[];
  byId: {
    [key: string]: ExtendedTask;
  };
  ids: string[];
  incompleteTaskId: string | null;
  openTasks: ProcessTask[];
  showNotRequiredTasks: boolean;
}

export default {
  actionRequiredCount: 0,
  requiredTasks: [],
  ids: [],
  byId: {},
  incompleteTaskId: null,
  openTasks: [],
  showNotRequiredTasks: true
} as TaskState;
