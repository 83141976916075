import { SubmissionInfo, ProcessState, UpdateRequestBody, TaskUpdatedMessage } from 'state/processes/types';
import {
  Option,
  getOrElse,
  chain,
  some,
  none,
  map as mapOption,
  reduce
} from 'fp-ts/lib/Option';
import {
  assoc,
  pathOr,
  has
} from 'ramda';
import { UploadProgress } from 'admin/helpers';
import { isLockWrapper } from 'admin/components/forms/elements/lockWrapper';

export const isCurrentProcess = (state: ProcessState, id) =>
  state.process && state.process.id === id;

// getSelectedSubmission :: SubmissionInfo -> Submission
// Gets the current submission by `selectedSubmission` if set; If not, defaults
// to the value in `submissionInfo.submission`.
// `selectedSubmission` is only set for recurring tasks.
export const getSelectedSubmission = (
  submissionInfo: SubmissionInfo
): Submission => {
  const o = chain<number, Submission>(i => some(submissionInfo.submissions[i]))(
    submissionInfo.selectedSubmission
  );
  return getOrElse(() => submissionInfo.submission)(o);
};

export const buildSubmission = (options = {}): Submission => {
  return Object.assign(
    {
      createdAt: new Date(),
      updatedAt: new Date(),
      state: 'started' as SubmissionState,
      validTimestamp: null,
      values: {}
    },
    options
  );
};

// firstStartedIndex :: any[] | null -> Option<number>
// Returns the index of the first submission with state === "started".
// When no submissions have been started the index of the most recent submission is returned.
// This can occur when all submissions for a task have been skipped.
export const firstStartedIndex = (xs: any[] | null): Option<number> => {
  if (xs === undefined || xs === null || xs.length === 0) {
    return none;
  }
  const index = xs.findIndex(sub => sub.state === 'started');
  return index === -1 ? some(xs.length - 1) : some(index);
};

export const buildSubmissionUrl = (submissionInfo: SubmissionInfo) => {
  const { processId, stepId, taskId } = submissionInfo as SubmissionInfo;
  let url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/submission`;
  mapOption((index: number) => {
    const submissionId = submissionInfo.submissions[index].id;
    url = url + `/${submissionId}`;
  })(submissionInfo.selectedSubmission);

  return url;
};

export const stripUploadErrors = (
  submissionInfo: SubmissionInfo,
  submission: Submission
): Submission => {
  const uploadElements = submissionInfo.form.formElements.filter(
    fe => fe.type === 'FileUpload'
  );
  const isError = value => value.status !== UploadProgress.Error;

  uploadElements.forEach(ue => {
    if (isLockWrapper(submission.values[ue.name])) {
      submission.values[ue.name].value = submission.values[
        ue.name
      ].value.filter(isError);
    } else if (has(ue.name, submission.values)) {
      submission.values[ue.name] = submission.values[ue.name].filter(
        v => v.status !== UploadProgress.Error
      );
    }
  });
  return submission;
};

export const sendDangerToast = (context, title, text) => {
  context.dispatch('ToastStore/toast',
    { color: 'danger', title, text },
    { root: true }
  );
};

// buildUpdateRequestBody :: (Submission, Option<number>) -> {submission: Submission, selectedSubmission?: number}
export const buildUpdateRequestBody = (
  submission: Submission,
  selectedSubmission: Option<number>
): UpdateRequestBody =>
  reduce(
    { submission } as UpdateRequestBody,
    (b: UpdateRequestBody, i: number) => assoc('selectedSubmission', i, b)
  )(selectedSubmission);

export const submissionUpdateSuccess = context => res => {
  context.commit('submissionProgress', 'success');
  context.commit('updateTaskUpdatedBy', res.task);
  
  const validationInfo = {
    taskId: res.task.id,
    submissionId: res.submission.submission_id,
    valid: res.submission_valid
  };
  context.commit('updateTaskPreValidation', validationInfo);

  if (res.alerts.number_out_of_range) {
    const title = 'Alert';
    const text = 'Number submitted outside of acceptable range.';
    sendDangerToast(context, title, text);
  }
  
  if (res.alerts.double_blind_mismatch) {
    const title = 'Alert';
    const text = 'Values submitted for the Double-blind element do not match.';
    sendDangerToast(context, title, text);
  }
  
  return res;
};

// buildCopyRequestBody :: (Submission, Option<number>) ->
// {submission: Submission, selectedSubmission?: number, requiredElements: Uuid[]}
export const buildCopyRequestBody = (
  submission: Submission,
  selectedSubmission: Option<number>,
  requiredElements: Uuid[]
): UpdateRequestBody =>
  reduce(
    { submission, requiredElements } as UpdateRequestBody,
    (b: UpdateRequestBody, i: number) => assoc('selectedSubmission', i, b)
  )(selectedSubmission);

export const buildCopySubmissionUrl = (submissionInfo: SubmissionInfo) => {
  const { processId, stepId, taskId } = submissionInfo as SubmissionInfo;
  let url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/copy`;
  mapOption((index: number) => {
    const submissionId = submissionInfo.submissions[index].id;
    url = url + `/${submissionId}`;
  })(submissionInfo.selectedSubmission);

  return url;
};

export const buildUpdateSummaryRequestBody = process => {
  process.submission.values = pathOr(
    process.submission.values,
    ['submission', 'clonedValues'],
    process
  );
  return {
    submission: process.submission
  };
};

export const displayUpdateSuccessToast = (
  context,
  message: TaskUpdatedMessage
) => {
  const shouldOmit = pathOr(
    false,
    ['msg_options', 'omit_frontend_toast'],
    message
  );
  if (!shouldOmit) {
    const title = 'Success';
    context.dispatch('ToastStore/toast',
      { color: 'success', title, text: 'Update was successful.' },
      { root: true }
    );
  }
};
