





















































































































































































































































































































































































import { isAdmin, isStaff } from 'admin/helpers/user';
import { mapGetters } from 'vuex';
import { equals } from 'ramda';

export default {
  inject: {
    localizationOverrides: {
      default: () => () => ({})
    }
  },
  computed: {
    ...mapGetters('UserStore', ['currentUser']),
    isLoggedIn() {
      return Boolean(window.currentUser);
    },
    currentRole() {
      const currentUser: User = window.currentUser;
      return (currentUser.employeeType || '').toLowerCase();
    },
    formattedVersion() {
      const version = window.App.version;
      return `v${version}`;
    },
    supportHref() {
      if (window.App.internalHelpContact) {
        const ihc = window.App.internalHelpContact;
        let mailingList = 'mailto:';
        Object.keys(ihc).forEach((contact) => {
          mailingList = mailingList.concat(ihc[contact].value, ';');
        })
        return mailingList;
      } else {
        return 'https://weeverapps.com/support/';
      }
    },
    features() {
      return window.App.features;
    },
    showDocumentsNewLabel() {
      return !equals(this.features, ['documents']);
    },
    title() {
      return this.localizationOverrides()?.title || this.$t('title');
    },
    logoImgAlt() {
      return `${this.title} Logo`;
    }
  },
  methods: {
    editProfile() {
      this.$store.commit('UserStore/editCurrentUser');
      this.$root.$emit('bv::show::modal', 'editProfileModal');
    },
    logout() {
      this.$store
        .dispatch('UserStore/signOut')
        .then(() => {
          this.$store.commit('UserStore/clearUser');
          window.location.href = '/';
        })
        .catch(err => console.error('failed to sign out', err));
    },
    isAdmin(): boolean {
      return isAdmin(this.currentUser);
    },
    isStaff(): boolean {
      return isStaff(this.currentUser);
    }
  }
};
