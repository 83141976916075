import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface NumberCheckField extends FormElement {
  type: 'NumberCheckField';
  name: string;
  label: string;
  content?: string;
  required: boolean;
  min?: number;
  max?: number;
  places?: number;
}

const service: FormBuilderElementService<NumberCheckField> = {
  getEmptyElement: (id: string): NumberCheckField => {
    return {
      id,
      name: '',
      label: 'Number Check',
      type: 'NumberCheckField',
      content: 'Include any instruction on how to perform the measurement or procedure (optional).',
      required: false,
      min: null,
      max: null,
      places: 0
    };
  }
};

export default service;
