
import { ProposalReviewStatus, ProposalReview } from 'state/proposal_reviews/types';
const isNotifiableReview = ((review: ProposalReview) => [ProposalReviewStatus.ChangesRequested, ProposalReviewStatus.Pending, ProposalReviewStatus.New].includes(review.reviewStatus));
const sortReviews = (reviews: ProposalReview[]) =>  reviews.sort((a, b) => a.reviewOrderIndex - b.reviewOrderIndex);
const isNotCancelled = (pr: ProposalReview) => pr.reviewStatus !== ProposalReviewStatus.Cancelled;

export default {
  proposalReviewsAdded: (state) => state.editReviews.filter((review) => review.reviewStatus === ProposalReviewStatus.New),
  activeProposalReviews: (state) => state.editReviews.filter(isNotCancelled),
  currentActiveReviewer: (state) => sortReviews(state.editReviews).find(isNotifiableReview),
  orderedReviewer: (_, getters): ProposalReview | undefined => sortReviews(getters.activeProposalReviews).find(isNotifiableReview)
}
