import { BaseState, buildState, baseAssetMutations } from 'admin/state/base_store';

interface DepartmentState extends BaseState {
  department: Department;
  departments: Department[];
}

interface OneDepartment {
  department: Department;
}

interface PaginatedDepartments extends PaginatedResources {
  departments: Department[];
}

const DepartmentStore = {
  namespaced: true,
  state: buildState<DepartmentState>({
    departments: [],
    department: { status: 'published' }
  }),
  mutations: baseAssetMutations<DepartmentState, OneDepartment, PaginatedDepartments>('department'),
  actions: {
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/departments',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    create(context, form) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/departments',
          type: 'post',
          data: form,
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    edit(context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${id}/edit`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    update(context, department) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${department.id}`,
          type: 'put',
          data: {
            department
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    destroy(_context, departmentId) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${departmentId}`,
          type: 'delete',
          success: (data) => {
            resolve(data);
          },
          error: (data) => {
            reject(data);
          }
        });
      });
    }
  }
};

export default DepartmentStore;
