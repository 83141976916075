import { UserState, PaginatedUsers } from 'admin/state/users/types';
import { many, progress, errors, updateModel, clearModel } from 'admin/state/base_store';

export default {
  edit(state, data) {
    state.errors = {};
    state.user = data;
    state.tempUser = Object.assign({}, state.user);
    return state;
  },
  editCurrentUser(state) {
    state.errors = {};
    state.user = window.currentUser;
    state.tempUser = Object.assign({}, state.user);
  },
  many: many<UserState, PaginatedUsers>('users'),
  progress,
  errors,
  updateUser: updateModel<UserState>('tempUser'),
  clearUser: clearModel<UserState>('user'),
  clearTempUser: clearModel<UserState>('tempUser'),
  doNothing(_state) {
    /* no-op */
  }
}
