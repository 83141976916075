export interface DocumentActivity {
  id?: string;
  text: string;
}

export interface DocumentActivityState {
  activities: DocumentActivity[];
}

const DocumentActivityStore = {
  namespaced: true,
  state: {
    activities: []
  } as DocumentActivityState,
  mutations: {
    many(state: DocumentActivityState, activities: DocumentActivity[]) {
      state.activities = activities;
    },
  },
  actions: {
    index(context, resourceId: string) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `documents/versions/${resourceId}/activities`,
          type: 'get',
          success: (data) => {
            context.commit('many', data.activities);
            resolve(data);
          },
          error: reject
        });
      });
    }
  }
};

export default DocumentActivityStore;
