import { AdminProcedureState } from 'admin/state/procedures/types';
import { buildState } from 'admin/state/base_store';

export default buildState<AdminProcedureState>({
  procedures: [],
  procedure: {
    title: '',
    status: 'published',
    steps: [],
    summaryForm: {},
    procedureForms: [],
    procedureSummaryFormTaskRules: [],
    notificationActionsRequired: false,
    showNotRequired: false
  },
  history: [],
  summaryModalToggled: false,
  step: {
    title: '',
    tasks: []
  },
  task: {
    step: -1,
    title: ''
  },
  hasUpdated: false,
  savedProcedure: {},
  stepIndex: 0
});
