import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface UserLookup extends FormElement {
  type: 'UserLookup';
  name: string;
  label: string;
  placeholder?: string;
  required: boolean;
}

const service: FormBuilderElementService<UserLookup> = {
  getEmptyElement: (id: string): UserLookup => {
    return {
      id,
      name: '',
      label: 'User Lookup',
      type: 'UserLookup',
      placeholder: 'Please select one…',
      required: false
    };
  }
};

export default service;
