import Vue from 'vue';
import { isDate, toLocal, toLocalDate, toLocalTime, toLocalSentence, timeSince } from 'helpers/time';

export const formatDateTime = (date: string): string => {
  if (!isDate(date)) { return ''; }
  return toLocal(date);
};

export const formatDateOnly = (date: string): string => {
  if (!isDate(date)) { return ''; }
  return toLocalDate(date);
};

export const formatTime = (date: string): string => {
  if (!isDate(date)) { return ''; }
  return toLocalTime(date);
};

export const formatTimeSentence = (date: string): string => {
  if (!isDate(date)) { return ''; }
  return toLocalSentence(date);
};
export const formatDate = (date: string): string => {
  if (!isDate(date)) { return ''; }
  return toLocalDate(date);
};

Vue.filter('toLocal', formatDateTime);
Vue.filter('toLocalDate', formatDateOnly);
Vue.filter('toLocalTime', formatTime);
Vue.filter('toLocalSentence', formatTimeSentence);
Vue.filter('timeSince', timeSince);
Vue.filter('toLocalDate', formatDate);
