import state from 'state/versions/state';
import getters from 'state/versions/getters';
import mutations from 'state/versions/mutations';
import actions from 'state/versions/actions';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
