import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface PasswordField extends FormElement {
  type: 'PasswordField';
  name: string;
  label: string;
  placeholder?: string;
  required: boolean;
}

const service: FormBuilderElementService<PasswordField> = {
  getEmptyElement: (id: string): PasswordField => {
    return {
      id,
      name: '',
      label: 'Password',
      type: 'PasswordField',
      placeholder: 'Enter password...',
      required: true
    };
  }
};

export default service;
