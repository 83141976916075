import { Applicative1 } from 'fp-ts/lib/Applicative';
import { has } from 'ramda';

export const URI = 'LockWrapper';
export type URI = typeof URI;
declare module 'fp-ts/lib/HKT' {
  interface URItoKind<A> {
    LockWrapper: LockWrapper<A>;
  }
}

export interface LockWrapper<A> {
  is_locked: boolean;
  signed_by: string;
  signed_by_with_email: string;
  timestamp: Timestamp;
  meaning_of_signature: string;
  value: A;
}

export const isLockWrapper = (a: any): boolean =>
  a ? has('is_locked', a) : false;

export const getLockedValue = <T>(a: LockWrapper<T> | T): T =>
  isLockWrapper(a) ? (a as LockWrapper<any>).value : a;

export const isLocked = (lw: LockWrapper<any>): boolean => lw.is_locked;

const of = <A>(a: A): LockWrapper<A> => {
  return {
    is_locked: false,
    signed_by: '',
    signed_by_with_email: '',
    timestamp: '',
    meaning_of_signature: '',
    value: a
  };
};

// function ap
const ap = <A, B>(fab: LockWrapper<(a: A) => B>, fa: LockWrapper<A>): LockWrapper<B> => {
  return of(fab.value(fa.value));
};

const map = <A, B>(fa: LockWrapper<A>, f: (a: A) => B): LockWrapper<B> => {
  return { ...fa, value: f(fa.value) };
};

// applicative instance for `LockWrapper`
export const applicativeLockWrapper: Applicative1<URI> = {
  URI,
  ap,
  map,
  of
};
