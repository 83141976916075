import { has, startsWith } from 'ramda';
import { displayResourceLockedMsg } from 'admin/helpers';
import { ActionContext } from 'vuex';

const updateLockedValues = (context: any, data: any) => (key: string) => {
  const value = data.values[key];
  if (value && has('is_locked', value) && !startsWith('user_data_', key)) {
    context.commit('ProcessStore/updateSubmission', { key, value }, { root: true });
  }
};

const SubmissionStore = {
  namespaced: true,
  state: {
    submissions: [],
    pagination: {},
  },
  mutations: {
    many(state, data) {
      state.submissions = data.submissions;
      state.pagination = data.pagination;
      return state;
    },
  },
  actions: {
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/forms/${query.id}/${query.version}/submissions`,
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    fetchHistory(_context: any, submissionId: Uuid) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/submissions/${submissionId}/history`,
          type: 'get',
          success: (data) => {
            resolve(data.history);
          },
          error: reject
        });
      });
    },
    sign(context: any, { processId, stepId, taskId, submissionId, signatureId, values, override }) {
      /* tslint:disable:max-line-length */
      let url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/submission/${submissionId}/sign/${signatureId}`;
      if (override) {
        url += '?override=true';
      }
      
      /* tslint:enable:max-line-length */
      return new Promise((resolve, reject) => {
        $.ajax({
          url,
          type: 'post',
          data: values,
          success: (data) => {
            Object.keys(data.values).forEach(updateLockedValues(context, data));
            resolve(data);
          },
          error: (err) => {
            if (err.status === 423) {
              displayResourceLockedMsg(context);
            }
            reject(err)
          }
        });
      });
    },
    /* tslint:disable:max-line-length */
    removeSignature(context: ActionContext<SubmissionState, SubmissionState>, { processId, stepId, taskId, submissionId, signatureId, signaturesRemoved }) {
      const url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/submission/${submissionId}/remove_signature/${signatureId}`;
      /* tslint:enable:max-line-length */
      return new Promise((resolve, reject) => {
        $.ajax({
          url,
          type: 'post',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify({
            signaturesRemoved
          }),
          success: resolve,
          error: (err) => {
            if (err.status === 423) {
              displayResourceLockedMsg(context);
            } else {
              context.dispatch('ToastStore/toast', {
                title: 'Error',
                text: 'Failed to remove signature.',
                color: 'danger'
              }, { root: true });
            }
            reject()
          }
        });
      });
    }
  }
};

export default SubmissionStore;
