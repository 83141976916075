const AppStore = {
  namespaced: true,
  state: {
    session: {},
  },
  mutations: {
    updateSession(state, { key, value }){
      state.session[key] = value;
    },
  },
  actions: {
  }
};

export default AppStore;
