import { ActionContext } from 'vuex';
import { Folder, FolderResponse } from './types';

export default {
  async getFolder(context: any, { id }: { id: string; }) {
    const url = `admin/folders/${id}`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'get',
        success: (data: FolderResponse) => {
          resolve(data);
        },
        error: reject
      });
    });
  },

  async getFolderStatus(context: any, { id }: { id: string; }) {
    const url = `admin/folders/${id}/status`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'get',
        success: (data) => resolve(data.status),
        error: reject
      });
    });
  },

  getFolders(_context: any, query: any) {
    const url = 'admin/folders';

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'get',
        data: query,
        success: resolve,
        error: reject
      });
    });
  },

  createFolder(_context: any, folder: Folder) {
    const url = 'admin/folders';

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'post',
        data: { folder },
        success: (data) => {
          resolve(data.folder);
        },
        error: (err) => reject(err.responseJSON)
      });
    });
  },

  // Used to populate the edit Folder text field 
  async getEditFolderName(context: ActionContext<any, any>, folder) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/folders/${folder.id}`,
        type: 'get',
        success: data => {
          // commit mutation
          context.commit('SET_FOLDER_TO_EDIT', data.folder);
          resolve(data.folder.title);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  async updateFolder(context: any, folder: Folder) {
    const url = `admin/folders/${folder.id}`;
    delete folder.id;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        data: { folder },
        success: (data) => {
          context.commit('UPDATE_MODEL', { key: 'title', value: data.folder?.title });
          resolve(data.folder);
        },
        error: (err) => reject(err.responseJSON)
      });
    });
  },

  async deleteFolder(_context: any, folder: Folder) {
    const url = `admin/folders/${folder.id}`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'delete',
        data: { folder },
        success: (data) => {
          // context.commit('REMOVE_FOLDER', data.folder);
          resolve(data.folder);
        },
        error: (err) => reject(err.responseJSON)
      });
    });
  },

  trashFolder({ commit, dispatch }, folderId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/folders/${folderId}/trash_folder`,
        type: 'put',
        success: () => {
          const opts = {
            title: 'Folder trashed',
            text: 'The Folder was moved to the trash. You can restore this folder later if you wish.',
            color: 'success'
          };
          dispatch('ToastStore/toast', opts, { root: true });
          commit('SET_FOLDER_TO_TRASH', null);
          resolve();
        },
        error: (err) => {
          console.error('failed to trash folder', err);
          const opts = {
            title: 'Update failed',
            text: 'Please try again, check your internet connection or contact support.',
            color: 'danger'
          }
          dispatch('ToastStore/toast', opts, { root: true });
          reject();
        }
      });
    });
  },

  restoreFolder({ commit, dispatch }, { id, folderId, status }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/folders/${id}/restore_folder`,
        type: 'put',
        data: { folderId, status },
        success: () => {
          const opts = {
            title: 'Folder restored',
            text: 'The folder was successfully restored.',
            color: 'success'
          };
          dispatch('ToastStore/toast', opts, { root: true });
          commit('SET_FOLDER_TO_RESTORE', null);
          resolve();
        },
        error: (err) => {
          console.error('failed to restore folder', err);
          const opts = {
            title: 'Update failed',
            text: 'Please try again, check your internet connection or contact support.',
            color: 'danger'
          }
          dispatch('ToastStore/toast', opts, { root: true });
          reject();
        }
      });
    });
  },

  hideFolder({ commit, dispatch }, { id, folderId }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/folders/${id}/hide_folder`,
        type: 'put',
        data: { folderId },
        success: () => {
          const opts = {
            title: 'Folder hidden',
            text: 'The folder was successfully hidden.',
            color: 'success'
          };
          dispatch('ToastStore/toast', opts, { root: true });
          commit('SET_FOLDER_TO_HIDE', null);
          resolve();
        },
        error: (err) => {
          console.error('failed to hide folder', err);
          const opts = {
            title: 'Update failed',
            text: 'Please try again, check your internet connection or contact support.',
            color: 'danger'
          }
          dispatch('ToastStore/toast', opts, { root: true });
          reject();
        }
      });
    });
  }
}
