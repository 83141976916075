import state from 'state/session/state';
import getters from 'state/session/getters';
import mutations from 'state/session/mutations';
import actions from 'state/session/actions';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
