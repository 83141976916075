import { has } from 'ramda';
import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export type Operators = '+' | '-' | '*' | '/';
interface BasicField { operator?: Operators; }
export interface ElementField extends BasicField { operand: string; }
export interface ConstantField extends BasicField { constant: number; }
export interface CalculationField {
  operand?: string;
  operator?: Operators;
  constant?: number;
}

export interface CalculationResult extends FormElement {
  type: 'CalculationResult';
  name: string;
  label: string;
  operands: CalculationField[];
}

const service: FormBuilderElementService<CalculationResult> = {
  getEmptyElement: (id: string): CalculationResult => {
    return {
      id,
      name: '',
      label: 'Calculation result',
      type: 'CalculationResult',
      operands: []
    };
  }
};

const isElementField = has('operand');
const getLabelById = (id: string, elements: FormElement[]): string => {
  const label = (elements.find((e) => e.id === id) || { label: null }).label;
  return `{${label || id}}`;
};
const getOperator = (operator: Operators | undefined, index: number): string => {
  if (index === 0 || ! operator) { return ''; }

  switch (operator) {
  case '+':
    return '+ ';
  case '-':
    return '− ';
  case '*':
    return '× ';
  case '/':
    return '÷ ';
  }
};

export const describeCalculation = (calculation: CalculationResult, formElements: FormElement[]) => {
  const operations = calculation.operands.map((f: CalculationField, index: number) => {
    const value = isElementField(f) ?
      getLabelById(f.operand, formElements) :
      f.constant;
    return getOperator(f.operator, index) + value;
  });

  return `${calculation.label} = ${operations.join(' ')}`;
};

export default service;
