import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface DropDown extends FormElement {
  type: 'DropDown';
  name: string;
  required: boolean;
  children: object;
}

const service: FormBuilderElementService<DropDown> = {
  getEmptyElement: (id: string): DropDown => {
    return {
      id,
      name: '',
      label: 'Please select one…',
      type: 'DropDown',
      required: false,
      children: [
        { text: 'Option A', value: 'Option A' },
        { text: 'Option B', value: 'Option B' },
        { text: 'Option C', value: 'Option C' }
      ]
    };
  }
};

export default service;
