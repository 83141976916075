import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface FileUpload extends FormElement {
  type: 'FileUpload';
  name: string;
  label: string;
  max: number;
  required: boolean;
  content: string;
}

const service: FormBuilderElementService<FileUpload> = {
  getEmptyElement: (id: string, text: string): FileUpload => {
    return {
      id,
      name: '',
      label: text || 'File upload',
      type: 'FileUpload',
      max: 6,
      required: false,
      content: text === 'Photo Upload' ? 'photo' : 'file'
    };
  }
};

export default service;
