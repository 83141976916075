import { ActionContext } from 'vuex';

export interface UserAccessActivity {
  id: number;
  userId: number;
  eventName: string;
  details: null | object;
  createdAt: Timestamp;
  ipAddress: string;
  message: string;
}

export interface UserAccessActivitiesState {
  activities: UserAccessActivity[];
  pagination: Pagination;
}

export interface UserAccessActivitiesResponse {
  activities: UserAccessActivity[];
  pagination: Pagination;
}

const UserAccessActivitiesStore = {
  namespaced: true,
  state: {
    activities: [],
    pagination: {}
  },
  mutations: {
    many(state: UserAccessActivitiesState, data: UserAccessActivitiesResponse): UserAccessActivitiesState {
      state.activities = data.activities;
      state.pagination = data.pagination;
      return state;
    }
  },
  actions: {
    paginated(
      context: ActionContext<UserAccessActivitiesState, UserAccessActivitiesState>,
      queryParams: Pagination
    ) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/user_access_activities',
          type: 'get',
          data: queryParams,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    }
  }
};

export default UserAccessActivitiesStore;
