










































































import { includes, pathOr } from 'ramda';
import { longSignatureMeaning } from 'helpers/signature';

export default {
  props: {
    control: Object,
    lockWrapper: Object,
    taskStatus: String
  },
  computed: {
    isLocked() {
      return pathOr(false, ['is_locked'], this.lockWrapper);
    },
    isUnlockable() {
      // task status is undefined in form reports where signatures are unlockable
      return !includes(this.taskStatus, ['not_required', 'done', undefined]);
    },
    isReadonlyText() {
      // property to support template variation
      return includes(this.taskStatus, ['done', undefined]);
    },
    signatureMeaning() {
      return longSignatureMeaning(this.lockWrapper.meaning_of_signature);
    }
  }
};
