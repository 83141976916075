import { ToastMessage, ToastState } from './types';

const mutations = {
  TOAST(state: ToastState, message: ToastMessage) {
    state.messages.push(message);
  },

  REMOVE(state: ToastState, id: string) {
    const i = state.messages.findIndex((x: ToastMessage) => x.id === id);
    if (i !== -1) {
      state.messages.splice(i, 1);
    }
  }
}

export default mutations;
