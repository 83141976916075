import { DocumentsState } from 'state/documents/types';
import { expiryColor, expiryBadge } from 'components/documents/versionHelper';
import { pathOr } from 'ramda';

export default {
  hasCurrentId: (state: DocumentsState) => !!state.currentId,
  extension: (state: DocumentsState) => {
    return state.editDocument.extension ? state.editDocument.extension.split('.').pop().toUpperCase() : ''
  },
  fullname: (state: DocumentsState) => {
    return `${pathOr('', ['editDocument', 'author', 'firstName'], state)} ${pathOr('', ['editDocument', 'author', 'lastName'], state)}`;
  },
  expiryColor: (_, getters) => {
    return expiryColor(getters.expiryBadge);
  },
  expiryBadge: (state: DocumentsState) => {
    return expiryBadge(state.editDocument.expiry);
  }
};
