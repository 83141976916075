import Vue from 'vue';
import { clone, pick } from 'ramda';
import { LoadProgress } from 'admin/helpers';
import { Version, VersionState } from 'state/versions/types';
import { Folder } from 'state/folders/types';

const pickVersionProps = (v: any) => pick(
  ['id', 'versionId', 'documentId', 'documentStatus', 'number', 'status',
    'title', 'expiry', 'createdAt', 'updatedAt', 'url', 'filename', 'uploadedAt',
    'extension', 'isVideo', 'draftProposal', 'author', 'folders', 'commentCount', 'tags', 'reviews', 'enforceReviewOrder'], v);

export default {
  SET_VERSION(state: VersionState, data) {
    data.reviews = data.reviews || [];
    data.enforceReviewOrder = data.enforceReviewOrder || false;
    Vue.set(state, 'version', clone(data));
  },

  SET_VERSIONS(state: VersionState, data: any) {
    const versions = clone(data.versions.filter((v) => !v.folderId));

    versions.map(pickVersionProps).map((version: Version) => {
      const id = version.versionId;
      const ids = Array.from(new Set([...state.ids, id]));

      Vue.set(state.byId, id, version);
      state.ids = Array.from(new Set(ids));
    });
  },

  SET_DRAFT_VERSIONS(state: VersionState, data: { versions: any[]; }) {
    const versions = clone(data.versions.filter((v) => !v.folderId));

    versions.map(pickVersionProps).map((version: Version) => {
      const id = version.versionId;
      const ids = Array.from(new Set([...state.draftIds, id]));

      Vue.set(state.draftsById, id, version);
      state.draftIds = Array.from(new Set(ids));
    });
  },

  SET_TRASHED_VERSIONS(state: VersionState, data: any) {
    const versions = clone(data.versions.filter((v) => !v.folderId));

    versions.map(pickVersionProps).map((version: Version) => {
      const id = version.versionId;
      const ids = Array.from(new Set([...state.trashedIds, id]));

      Vue.set(state.trashedById, id, version);
      state.trashedIds = Array.from(new Set(ids));
    });
  },

  SET_FOLDERS(state: VersionState, data: any) {
    let folders = clone(data.versions.filter((d: any) => !!d.folderId));
  
    folders = folders.map((f) => ({
      id: f.folderId,
      parentId: f.folderParentId,
      status: f.folderStatus,
      title: f.folderTitle,
      numberOfChildren: f.numberOfChildren
    }));

    folders.map((folder: Folder) => {
      const id = folder.id;
      const ids = Array.from(new Set([...state.folderIds, id]));

      Vue.set(state.byFolderId, id, folder);
      state.folderIds = Array.from(new Set(ids));
    });
  },

  REFRESH_VERSION_IN_DRAFTS_BY_ID(state: VersionState, version: Version) {
    state.draftsById[version.versionId] = version;
  },

  REFRESH_VERSION_IN_TRASHED_BY_ID(state: VersionState, version: Version) {
    state.trashedById[version.versionId] = version;
  },

  REFRESH_VERSION_IN_BY_ID(state: VersionState, version: Version) {
    state.byId[version.versionId] = version;
  },

  UPDATE_VERSION(state: VersionState, { key, value }) {
    if (!state.version) {
      state.version = {
        tags: []
      } as Version;
    }
    state.version[key] = value;
  },

  CLEAR_VERSION(state: VersionState) {
    state.version = {
      tags: [],
      reviews: [],
      enforceReviewOrder: false
    } as Version;
  },

  CLEAR_VERSIONS(state: VersionState) {
    state.byId = {};
    state.ids = [];
  },

  CLEAR_DRAFT_VERSIONS(state: VersionState) {
    state.draftsById = {};
    state.draftIds = [];
  },

  CLEAR_TRASH_VERSIONS(state: VersionState) {
    state.trashedById = {};
    state.trashedIds = [];
  },

  CLEAR_FOLDERS(state: VersionState) {
    state.byFolderId = {};
    state.folderIds = [];
  },

  UPDATE_PROGRESS(state: VersionState, newValue: LoadProgress) {
    state.progress = newValue;
  }
};
