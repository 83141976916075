import { LoadProgress } from 'admin/helpers';
import { Folder } from 'state/folders/types';
import { ProposalReview } from 'state/proposal_reviews/types';

export interface TargetVersionIds {
  documentId: Uuid;
  versionId: Uuid;
}

export interface Version {
  id: number;
  versionId: string;
  documentId: string;
  number: number;
  status: string;
  title: string;
  expiry: string;
  createdAt: string;
  updatedAt: string;
  url: string;
  filename: string;
  uploadedAt: string;
  extension: string;
  iconClass?: string;
  isVideo: boolean;
  draftProposal: Proposal;
  tags: string[];
  reviews: ProposalReview[];
  enforceReviewOrder: boolean;
  author?: {
    email: string;
    firstName: string;
    lastName: string;
  } | string;
}

export interface VersionState {
  versions: Version[];
  progress: LoadProgress;
  version?: Version;
  ids?: string[];
  draftIds?: string[];
  trashedIds?: string[];
  folderIds?: (string |  number)[];
  byId?: Record<string, Version>;
  draftsById?: Record<string, Version>;
  trashedById?: Record<string, Version>;
  byFolderId?: Record<string, Folder>;
}

export enum VersionStatus {
  Published = 'published',
  Draft = 'draft',
  Archived = 'archived',
  Discarded = 'discarded'
}

export enum ProposalStatus {
  Rejected = 'rejected',
  Approved = 'approved',
  Pending = 'pending',
  RequestedChanges = 'requested_changes'
}

export interface Proposal {
  title: string;
  fileupload: any;
  status: ProposalStatus;
  updatedAt: string;
  proposalReviews: ProposalReview[];
}
