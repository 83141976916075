import { LoadProgress } from 'admin/helpers';
import { VersionState } from 'state/versions/types';

export default {
  ids: [],
  draftIds: [],
  trashedIds: [],
  folderIds: [],
  byId: {},
  draftsById: {},
  trashedById: {},
  byFolderId: {},
  progress: LoadProgress.Init,
  version: {
    tags: [],
    reviews: [],
    enforceReviewOrder: false
  }
} as VersionState;
