import { always, isNil, pipe, when } from 'ramda';

export const capitalize = (value: any): string => {
  if (!value) { return ''; }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const userName = pipe(
  when(isNil, always({ first_name: 'Unknown', last_name: 'User' })),
  (u: LegacyUser) => `${u.first_name} ${u.last_name}`
);

export const join = (value: any) => {
  const values = Array.isArray(value) ? value : [];
  return values.join(', ');
};
