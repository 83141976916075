import Vue from 'vue';
import { Attribute, Folder, FolderState } from './types';

export default {
  CLEAR_MODEL(state: FolderState) {
    Vue.set(state, 'modelFolder', { title: '' });
  },

  INIT_MODEL(state: FolderState, folder: Folder) {
    Vue.set(state.modelFolder, 'title', folder.title);
    Vue.set(state.modelFolder, 'id', folder.id);
    Vue.set(state.modelFolder, 'parentId', folder.parentId);
  },

  UPDATE_MODEL(state: FolderState, attribute: Attribute) {
    Vue.set(state.modelFolder, attribute.key, attribute.value);
  },

  UPDATE_FOLDER_TO_RESTORE(state: FolderState, attribute: Attribute) {
    Vue.set(state.folderToRestore, attribute.key, attribute.value);
  },

  SET_FOLDER_TO_TRASH(state: FolderState, folder: Folder) {
    Vue.set(state, 'folderToTrash', folder);
  },

  SET_FOLDER_TO_RESTORE(state: FolderState, folder: Folder) {
    Vue.set(state, 'folderToRestore', folder);
  },

  SET_FOLDER_TO_HIDE(state: FolderState, folder: Folder) {
    Vue.set(state, 'folderToHide', folder);
  },

  SET_FOLDER_TO_EDIT(state: FolderState, folder: Folder) {
    Vue.set(state, 'folderToEdit', folder);
  }
}
