import actions from 'admin/state/procedures/actions';
import getters from 'admin/state/procedures/getters';
import mutations from 'admin/state/procedures/mutations';
import state from 'admin/state/procedures/state';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
