import idle, { IdleSession } from 'helpers/idleSession';
import { InitState, SessionState } from 'state/session/state';

export default {
  SETUP_SESSION(state: SessionState, init: InitState) {
    const {
      hideWarningModal,
      hideAlertModal,
      showWarningModal,
      showAlertModal
    } = init;

    state.hideWarningModal = hideWarningModal;
    state.hideAlertModal = hideAlertModal;
    state.showWarningModal = showWarningModal;
    state.showAlertModal = showAlertModal;
  },

  INIT_SESSION(state: SessionState, { idleAlert, idleWarning }: IdleSession) {
    state.idleAlert = idleAlert;
    state.idleWarning = idleWarning;
  },

  START_SESSION(state: SessionState) {
    idle.startSession({
      idleAlert: state.idleAlert,
      idleWarning: state.idleWarning
    });
  },

  RESTART_SESSION(state: SessionState) {
    idle.restartSession({
      idleAlert: state.idleAlert,
      idleWarning: state.idleWarning
    });
  },

  STOP_SESSION(state: SessionState) {
    state.idleAlert = {};
    state.idleWarning = {};
  }
};
