



























export default {
  props: ['modalId', 'control', 'isRequired', 'labelProp', 'index', 'suffix'],
  computed: {
    customLabel() {
      return this.labelProp ? this.control[this.labelProp] : this.control.label;
    },
    suffixOrEmpty() {
      return this.suffix || '';
    }
  }
};
