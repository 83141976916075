import { GetCommentsRequest, CreateCommentRequest } from 'api/comments/types';

export const getComments = (opts: GetCommentsRequest) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `submissions/${opts.submissionId}/comments`,
      type: 'get',
      success: resolve,
      error: reject
    });
  });
};

export const createComment = (opts: CreateCommentRequest) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `submissions/${opts.submissionId}/comments`,
      type: 'post',
      data: {
        comment: {
          text: opts.text
        }
      },
      success: resolve,
      error: reject
    });
  });
}
