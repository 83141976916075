/**
 * Wrapper providing additional functionality to a promise
 */
export default class PromiseGuard<T> {
  currentPromise: Promise<T> | null = null;

  static STALE_REQUEST_KEY = 'StaleRequest' as const;
  /**
   * Avoid race conditions caused by subsequent calls by rejecting a promise if it is no longer
   * the latest one by the time it has completed.
   */
  async deRace(promise: Promise<T>): Promise<T> {
    this.currentPromise = promise;

    // This awaits execution of this function for at least as long as the promise takes to execute.
    await promise;

    // Sometime after the above promise resolves, we'll check to make sure that it is still the current promise.
    // Other calls to deRace on this guard may have happened in the meantime, so it may no longer be current.
    if (this.currentPromise === promise) {
      return await promise;
    } else {
      return Promise.reject(new Error(PromiseGuard.STALE_REQUEST_KEY));
    }
  }
}