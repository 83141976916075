export interface ProposalReviewState {
  editReviews: ProposalReview[];
  cancelledReviews: ProposalReview[];
  editReviewOrderIsOn: boolean;
  proposalReview?: ProposalReview;
}
export interface ProposalReview {
  proposalReviewId?: Uuid;
  reviewStatus: ProposalReviewStatus;
  previousReviewStatus?: ProposalReviewStatus;
  reviewComment?: string;
  reviewOrderIndex: number;
  approver: Approver;
}

export interface Approver {
  approverId: number;
  fullName: string;
  email: string;
  avatar: Record<string, any>;
}

export enum ProposalReviewStatus {
  Pending = 'pending',
  Approved = 'approved',
  ChangesRequested = 'requested_changes',
  New = 'new',
  ToCancel = 'to_cancel',
  Cancelled = 'cancelled',
  Restored = 'restored'
}

export interface NotifyReviewersPayload {
  documentId: Uuid;
  versionId: Uuid;
  proposalId: Uuid;
  reviewIds: string[];
}
export interface UpdateProposalReviewsArgs {
  documentId: Uuid;
  versionId: Uuid;
  proposalId: Uuid;
  reviews: ProposalReview[];
  cancelReviews: ProposalReview[];
  enforceReviewOrder: boolean;
}

export interface UserLookupEntry {
  value: {
    avatar: Record<string, any>;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
}
