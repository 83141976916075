import { AdminProcedureState } from 'admin/state/procedures/types';
import { clone } from 'ramda';
import Fuse from 'fuse.js';

interface SearchOptions {
  searchText: string;
}

const taskOptions: Fuse.FuseOptions<any> = {
  keys: ['title', 'form.name', 'owner.name', 'repetitionUnits', 'repetitionValue'],
  threshold: 0.3
};

const stepOptions: Fuse.FuseOptions<any> = {
  keys: ['title'],
  threshold: 0.3
};

export default {
  filteredProcedure: (state: AdminProcedureState) => ({ searchText }: SearchOptions) => {
    if (!searchText) { return state.procedure; }

    const procedure: Procedure = clone(state.procedure);
    procedure.steps = procedure.steps
      .map((step) => {
        step.tasks = new Fuse(step.tasks, taskOptions).search(searchText) as ProcedureTask[];
        return step;
      })
      .filter((step) => {
        const steps = new Fuse([step], stepOptions).search(searchText);
        return step.tasks.length > 0 || steps.length > 0;
      });
    return procedure;
  }
};
