import { BaseState, buildState, baseAssetMutations } from 'admin/state/base_store';

interface MachineTypeState extends BaseState {
  machine_type: MachineType;
  machine_types: MachineType[];
}

interface OneMachineType {
  machine_type: MachineType;
}

interface PaginatedMachineTypes extends PaginatedResources {
  machine_types: MachineType[];
}

const MachineTypeStore = {
  namespaced: true,
  state: buildState<MachineTypeState>({
    machine_types: [],
    machine_type: { status: 'published' }
  }),
  mutations: baseAssetMutations<MachineTypeState, OneMachineType, PaginatedMachineTypes>('machine_type'),
  actions: {
    index(context, query) {
      $.ajax({
        url: 'admin/machine_types',
        type: 'get',
        data: query,
        success: (data) => {
          context.commit('many', data);
        }
      });
    },
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/machine_types',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    autocomplete(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/machine_types/autocomplete',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    new(context) {
      $.ajax({
        url: 'admin/machine_types/new',
        type: 'get',
        success: (data) => {
          context.commit('one', data);
        }
      });
    },
    create(context, form) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/machine_types',
          type: 'post',
          data: {
            machine_type: form.machine_type
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    edit(context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${id}/edit`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    update(context, machineType) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${machineType.id}`,
          type: 'put',
          data: {
            machine_type: machineType
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    destroy(_context, machineTypeId) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/machine_types/${machineTypeId}`,
          type: 'delete',
          success: (data) => {
            resolve(data);
          },
          error: (data) => {
            reject(data);
          }
        });
      });
    }
  }
};

export default MachineTypeStore;
