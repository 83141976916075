











import { Avatar } from 'vue-avatar';

export default {
  components: { Avatar },
  props: {
    username: String,
    avatar: Object,
    size: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      avatarStyle: {
        fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontWeight: 600,
      }
    };
  },
  computed: {
    src() {
      return this.avatar ? this.avatar.value[0].imageUrl : null;
    }
  }
};
