import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface InfoBox extends FormElement {
  type: 'InfoBox';
  name: string;
  label: string;
  content: string;
}

const service: FormBuilderElementService<InfoBox> = {
  getEmptyElement: (id: string): InfoBox => {
    return {
      id,
      name: '',
      label: 'Infobox',
      type: 'InfoBox',
      content: null
    };
  }
};

export default service;
