import { ActionContext } from 'vuex';

export interface ProcessAlertsState {
  alerts: ProcessAlert[];
  pagination: Pagination;
}

export interface ProcessAlertsResponse {
  alerts: ProcessAlert[];
  pagination: Pagination;
}

const ProcessAlertStore = {
  namespaced: true,
  state: {
    alerts: [],
    pagination: {}
  },
  mutations: {
    many(state: ProcessAlertsState, data: ProcessAlertsResponse): ProcessAlertsState {
      state.alerts = data.alerts;
      state.pagination = data.pagination;
      return state;
    }
  },
  actions: {
    paginated(context: ActionContext<ProcessAlertsState, ProcessAlertsState>, queryParams: Pagination) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/process_alerts',
          type: 'get',
          data: queryParams,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    }
  }
};

export default ProcessAlertStore;
