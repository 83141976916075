import { fold } from 'fp-ts/lib/Option';
import { includes, equals } from 'ramda';
import { EmployeeType } from 'admin/helpers';

const adminRoles = [EmployeeType.Admin, EmployeeType.Supervisor];

// isAdmin :: Option<User> -> boolean
export const isAdmin = fold<User, boolean>(
  () => false,
  (user) => includes(user.employeeType, adminRoles)
);

// isSupervisor :: Option<User> -> boolean
export const isSupervisor = fold<User, boolean>(
  () => false,
  (user) => equals(user.employeeType, EmployeeType.Supervisor)
);

// isStaff :: Option<User> -> boolean
export const isStaff = fold<User, boolean>(
  () => false,
  (user) => equals(user.employeeType, EmployeeType.Staff)
);
