import moment, { Moment } from 'moment-timezone';

export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;
export const ONE_HOUR_IN_MS = 60 * ONE_MINUTE_IN_MS;

const API_DATETIME_FORMAT: string[] = [
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DD HH:mm:ss UTC'
];

export const LOCAL_TIMEZONE: string = (window && window.App && window.App.localTimezone) || 'America/Toronto';
export const TIME_24_HR: boolean = (window && window.App && window.App.time24hr);

type fdaDateFormat = 'DD-MMM-YYYY' | 'DD-MMM-YYYY hh:mma z' | 'DD-MMM-YYYY HH:mm z';
export const FDA_DATE_FORMAT: fdaDateFormat = 'DD-MMM-YYYY';
export const FDA_DATETIME_FORMAT: fdaDateFormat = TIME_24_HR ? 'DD-MMM-YYYY HH:mm z' : 'DD-MMM-YYYY hh:mma z';

const SENTENCE_FORMAT = TIME_24_HR ? 'DD-MMM-YYYY [at] HH:mm z' : 'DD-MMM-YYYY [at] hh:mm a z';

export const isDate = (date: Timestamp): boolean => {
  return moment(date, API_DATETIME_FORMAT, true).isValid();
};

export const isToday = (date: string): boolean => {
  const l_date = moment(date);
  const start = moment().tz(LOCAL_TIMEZONE).startOf('day');
  const end = moment().tz(LOCAL_TIMEZONE).endOf('day');
  return start <= l_date && l_date <= end;
};

export const isThisYear = (date: string): boolean => {
  const start = moment().tz(LOCAL_TIMEZONE).startOf('year').toISOString();
  const end = moment().tz(LOCAL_TIMEZONE).endOf('year').toISOString();

  return start <= date && date <= end;
};

const testDate = (date: Timestamp) => {
  if (!isDate(date)) {
    throw new Error(`date (${date}) must be in ISO8601 format`);
  }
};

export const toLocalMoment = (date: Timestamp): moment.Moment => {
  testDate(date);
  return moment.utc(date, API_DATETIME_FORMAT).tz(LOCAL_TIMEZONE);
};

export const toLocal = (date: string): string => {
  return toLocalMoment(date).format(FDA_DATETIME_FORMAT);
};

export const toLocalDate = (date: string): string => toLocal(date).substring(0, 11);

export const toLocalTime = (date: string): string => toLocal(date).substring(12);

export const toLocalSentence = (date: string): string => {
  testDate(date);
  return moment.tz(date, LOCAL_TIMEZONE).format(SENTENCE_FORMAT);
};

export const timeSince = (date: string) => moment.tz(date, LOCAL_TIMEZONE).fromNow();

export const duration = (nextCheck: string | Date, currentTime: Date = new Date()) => {
  const nc = moment(nextCheck);
  const now = moment(currentTime);
  return moment.duration(nc.diff(now)).asMilliseconds();
};

export const overdueDuration = (nextCheck: string | Date, currentTime: Date = new Date()) => {
  const now = moment(currentTime);
  const nc = moment(nextCheck);
  return moment.duration(now.diff(nc)).asMilliseconds();
};

export const isOverdue = (nextCheck: string | Date, currentTime: Date = new Date()) => {
  return duration(nextCheck, currentTime) < 0;
};

export const formattedDuration = (d: number): string => {
  let remainder: number;

  const hours = Math.floor(d / ONE_HOUR_IN_MS);
  remainder = d % ONE_HOUR_IN_MS;

  const minutes = Math.floor(remainder / ONE_MINUTE_IN_MS);
  remainder = remainder % ONE_MINUTE_IN_MS;

  const seconds = Math.floor(remainder / ONE_SECOND_IN_MS);

  return  [hours, minutes, seconds]
    .map((n) => n.toString())
    .map((s) => s.padStart(2, '0'))
    .join(':');
};
