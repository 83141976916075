import { IdleInstance } from 'helpers/idleSession';

export interface InitState {
  hideWarningModal?: () => void | void;
  hideAlertModal?: () => void | void;
  showWarningModal?: () => void | void;
  showAlertModal?: () => void | void;
  alertTimeout?: number;
  warningTimeout?: number;
}
export interface SessionState extends InitState {
  idleAlert?: IdleInstance;
  idleWarning?: IdleInstance;
}

export default {
  idleAlert: {},
  idleWarning: {},
  hideWarningModal: undefined,
  hideAlertModal: undefined,
  showWarningModal: undefined,
  showAlertModal: undefined,
  alertTimeout: 10800000, // 180 min (3hrs)
  warningTimeout: 9630000 // 160min (2hrs and 40min) + 30 seconds (give the server time to log them out)
} as SessionState;
