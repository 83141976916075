import { map } from 'ramda';
import { hydrate, HydratedActivityHistory } from 'admin/helpers/activities';
import { ActivityHistoryItem } from 'admin/helpers/processes';

export interface TaskEventState {
  task_events: HydratedActivityHistory[];
}

const TaskEventStore = {
  namespaced: true,
  state: {
    task_events: []
  } as TaskEventState,
  mutations: {
    many(state: TaskEventState, events: ActivityHistoryItem[]) {
      const process = this.state.ProcessStore.process;
      state.task_events = map((a) => hydrate(a, process), events);
    }
  },
  actions: {
    index(context, { processId, stepId, taskId }) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `processes/${processId}/steps/${stepId}/tasks/${taskId}/events`,
          type: 'get',
          success: (data) => {
            context.commit('many', data.events);
            resolve(data);
          },
          error: reject
        });
      });
    }
  }
};

export default TaskEventStore;
