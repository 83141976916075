












export default {
  props: ['modalId', 'control', 'errorMessages', 'forceShow', 'index']
};
