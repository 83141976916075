import { BaseState, buildState, baseAssetMutations } from 'admin/state/base_store';

interface LineWorkAreaState extends BaseState {
  line_work_areas: LineWorkArea[];
  line_work_area: LineWorkArea;
}

interface OneLineWorkArea {
  line_work_area: LineWorkArea;
}

interface PaginatedLineWorkAreas extends PaginatedResources {
  line_work_areas: LineWorkArea[];
}

const LineWorkAreaStore = {
  namespaced: true,
  state: buildState<LineWorkAreaState>({
    line_work_areas: [],
    line_work_area: { status: 'published' },
  }),
  mutations: baseAssetMutations<LineWorkAreaState, OneLineWorkArea, PaginatedLineWorkAreas>('line_work_area'),
  actions: {
    index(context, query) {
      const opts = {};
      Object.keys(query).forEach((prop) => {
        opts[`q[${prop}_cont]`] = query[prop];
      });
      $.ajax({
        url: `admin/departments/${query.department_id}/line_work_areas`,
        type: 'get',
        data: opts,
        success: (data) => {
          context.commit('many', data);
        }
      });
    },
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${query.department_id}/line_work_areas`,
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    autocomplete(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${query.department_id}/line_work_areas/autocomplete`,
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data.line_work_areas);
          },
          error: reject
        });
      });
    },
    new(context, departmentId) {
      $.ajax({
        url: `admin/departments/${departmentId}/line_work_areas/new`,
        type: 'get',
        success: (data) => {
          context.commit('one', data);
        }
      });
    },
    create(context, { lwa, departmentId }) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${departmentId}/line_work_areas`,
          type: 'post',
          data: {
            line_work_area: lwa
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    edit(context, params) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${params.department_id}/line_work_areas/${params.id}/edit`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    update(context, lineWorkArea) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${lineWorkArea.department_id}/line_work_areas/${lineWorkArea.id}`,
          type: 'put',
          data: {
            line_work_area: lineWorkArea
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    destroy(_context, lineWorkArea) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/departments/${lineWorkArea.department_id}/line_work_areas/${lineWorkArea.id}`,
          type: 'delete',
          success: (data) => {
            resolve(data);
          },
          error: (data) => {
            reject(data);
          }
        });
      });
    }
  }
};

export default LineWorkAreaStore;
