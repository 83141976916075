import { ActionContext } from 'vuex';
import { Either, left, right } from 'fp-ts/lib/Either';
import uuid from 'uuid';
import { UpdateTaskRuleStepPayload, DuplicateProcedurePayload } from './types';

export default {
  index(context, query) {
    $.ajax({
      url: 'admin/procedures',
      type: 'get',
      data: query,
      success: (data) => {
        context.commit('many', data);
      }
    });
  },
  paginated(context, query) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'admin/procedures',
        type: 'get',
        data: query,
        success: (data) => {
          context.commit('many', data);
          resolve(data);
        },
        error: reject
      });
    });
  },
  new(context, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'admin/procedures/new',
        type: 'get',
        success: (data) => {
          context.commit('one', data);
          resolve(id);
        },
        error: (err) => reject(err)
      });
    });
  },
  create(context, procedure: Procedure) {
    context.commit('progress', 'loading');
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'admin/procedures',
        type: 'post',
        data: { procedure },
        success: (data) => {
          // Reload the data from the API
          context.dispatch('edit', { id: data.procedure_id, version: data.version })
            .then(() => {
              context.commit('progress', 'success');
              resolve(data);
            });
        },
        error: (data) => {
          context.commit('progress', 'failed');
          context.commit('errors', data);
          reject(data);
        }
      });
    });
  },
  async createTask(context: ActionContext<any, any>, data: { stepIndex: number; stepId: Uuid; }) {
    const taskId = uuid.v4();
    context.commit('clearTask');
    context.commit('setIndex', data.stepIndex);
    context.commit('updateTaskModel', { key: 'stepId', value: data.stepId });
    context.commit('updateTaskModel', { key: 'step_id', value: data.stepId });
    context.commit('updateTaskModel', { key: 'taskId', value: taskId });
    context.commit('updateTaskModel', { key: 'task_id', value: taskId });
    context.commit('updateTaskModel', { key: 'procedureTaskRules', value: [] });
  },
  edit(context, { id, version }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `procedures/${id}/${version}`,
        type: 'get',
        success: (data) => {
          context.commit('one', data);
          resolve(data);
        },
        error: reject
      });
    });
  },
  removeTask(
    context,
    { stepIndex, taskIndex }: {stepIndex: number; taskIndex: number; }
  ): Either<string, boolean> {
    context.commit('removeTask', { stepIndex, taskIndex });
    return right(true);
  },
  removeStep(
    context,
    { stepIndex }: {stepIndex: number; }
  ): Either<string, boolean> {
    const steps = context.state.procedure.steps;
    const tasks = steps[stepIndex].tasks;

    if (tasks.length > 0) {
      return left('This step contains tasks.  Please remove any tasks within the step first.');
    }

    context.commit('removeStep', { stepIndex });
    return right(true);
  },
  update(context, procedure) {
    return new Promise((resolve, reject) => {
      context.commit('progress', 'loading');
      $.ajax({
        url: `admin/procedures/${procedure.id}/${procedure.version}`,
        type: 'put',
        data: { procedure },
        success: (data) => {
          context.commit('progress', 'success');
          resolve(data);
        },
        error: (data) => {
          context.commit('progress', 'failed');
          context.commit('errors', data);
          reject(data);
        }
      });
    });
  },
  destroy(_context, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/procedures/${id[0]}`,
        type: 'delete',
        success: (data) => {
          resolve(data);
        },
        error: (data) => {
          reject(data);
        }
      });
    });
  },

  updateTaskRuleStep({ commit }, payload: UpdateTaskRuleStepPayload) {
    return new Promise((resolve, _reject) => {
      commit('UPDATE_TASK_RULE_STEP', payload);
      resolve();
    });
  },

  duplicate(_, payload: DuplicateProcedurePayload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/procedures/${payload.procedureId}/${payload.procedureVersion}/duplicate`,
        type: 'put',
        success: resolve,
        error: reject,
        data: {
          process_category_id: payload.processCategoryId,
          process_category_version: payload.processCategoryVersion
        }
      });
    });
  },

  history(context, query) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/procedures/history/${query.id}`,
        type: 'get',
        data: query,
        success: (data) => {
          context.commit('history', data);
          resolve(data);
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  },
  
};
