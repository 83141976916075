import Vue from 'vue';
import { ExtendedTask, RequiredTask, TaskState } from 'state/tasks/types';

interface RequiredTasksResponse {
  actionRequiredCount?: number;
  processes?: RequiredTask[];
}

export default {
  setRequiredTasks(
    state: TaskState,
    { actionRequiredCount, processes }: RequiredTasksResponse
  ) {
    state.actionRequiredCount = actionRequiredCount;
    state.requiredTasks = processes;
  },

  SET_TASK(state: TaskState, task: ExtendedTask) {
    // Set the task to byId using Vue.set for reactivity.
    Vue.set(state.byId, task.id, task);

    // Add id to ids while making sure there are no duplicates.
    state.ids = Array.from(new Set([...state.ids, task.id]));
  },

  UPDATE_TASK(state: TaskState, { task }: { task: ProcessTask; }) {
    Object.keys(task).forEach(key => {
      if (state.byId[task.id]) {
        state.byId[task.id][key] = task[key];
      }
    });
  },

  CLEAR_TASKS(state: TaskState) {
    state.byId = {};
    state.ids = [];
  },

  SET_INCOMPLETE_TASK(state: TaskState, taskId: string) {
    state.incompleteTaskId = taskId;
  },

  CLEAR_INCOMPLETE_TASK(state: TaskState) {
    state.incompleteTaskId = null;
  },

  SET_SHOW_NOT_REQUIRED_TASKS(state: TaskState, showNotRequiredTasks: boolean) {
    state.showNotRequiredTasks = showNotRequiredTasks;
  },

  REMOVE_ASSIGNEE(state: TaskState, id: string) {
    state.byId[id].assignee = null;
  },

  SET_ASSIGNEE(state: TaskState, message: any) {
    const assignee = message.assignee
    if (state.byId[message.task_id]) {
      state.byId[message.task_id].assignee = assignee
    }
  }
};
