import { LoadProgress } from 'admin/helpers';
import {
  TargetVersionIds,
  DocumentSubmission,
  CreateDocumentResponse,
  VersionArgs,
} from 'state/documents/types';

export default {
  create({ commit }, payload: DocumentSubmission) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading);

    return $.ajax({
      url: 'documents/documents',
      type: 'post',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        document: payload
      }),
      success: (response: CreateDocumentResponse) => {
        commit('UPDATE_PROGRESS', LoadProgress.Success);
        return Promise.resolve(response);
      },
      error: error => {
        commit('UPDATE_PROGRESS', LoadProgress.Failed);
        // commit('errors', error); // Mutation does not exist.
        return Promise.reject(error);
      }
    });
  },

  trash({ commit }, documentId: Uuid) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading);
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${documentId}`,
        type: 'delete',
        success: response => {
          commit('UPDATE_PROGRESS', LoadProgress.Success);
          resolve(response);
        },
        error: err => {
          commit('UPDATE_PROGRESS', LoadProgress.Failed);
          reject(err);
        }
      });
    })
  },

  restore({ commit, dispatch }, { id, folders, status }) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading)
    return $.ajax({
      url: `documents/documents/${id}/restore`,
      type: 'put',
      data: {
        folders,
        status
      },
      success: response => {
        commit('UPDATE_PROGRESS', LoadProgress.Success);
        dispatch('ToastStore/toast', {
          title: 'Document restored',
          text: 'The Document was successfully restored.',
          color: 'success'
        }, { root: true });
        return Promise.resolve(response);
      },
      error: error => {
        commit('UPDATE_PROGRESS', LoadProgress.Failed);
        dispatch('ToastStore/toast', {
          title: 'Update failed',
          text: 'Please try again, check your internet connection or contact support.',
          color: 'danger'
        }, { root: true });
        Promise.reject(error);
      }
    })
  },

  hide({ commit }, documentId: Uuid) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading)
    return $.ajax({
      url: `documents/documents/${documentId}/hide`,
      type: 'put',
      success: response => {
        commit('UPDATE_PROGRESS', LoadProgress.Success);
        commit('UPDATE_EDIT_DOCUMENT', { key: 'documentStatus', value: 'hidden' });
        return Promise.resolve(response);
      },
      error: error => {
        commit('UPDATE_PROGRESS', LoadProgress.Failed);
        Promise.reject(error);
      }
    })
  },

  getAll({ commit, dispatch }, query) {
    return dispatch('VersionStore/published', query, { root: true })
      .then((response) => {
        response.versions.filter((v: any) => !v.folderId).forEach(version => {
          commit('SET_PUBLISHED_VERSION', version);
        });
        return response;
      });
  },
  getDrafts({ commit, dispatch }, query) {
    return dispatch('VersionStore/drafts', query, { root: true })
      .then((response) => {
        response.versions.filter((v: any) => !v.folderId).forEach(version => {
          commit('SET_DRAFT_VERSION', version);
        });
        return response;
      });
  },
  getTrashed({ commit, dispatch }, query) {
    return dispatch('VersionStore/trashed', query, { root: true })
      .then((response) => {
        response.versions.filter((v: any) => !v.folderId).forEach(version => {
          commit('SET_TRASHED_VERSION', version);
        });
        return response;
      });
  },

  edit({ commit }, target_ids: TargetVersionIds) {
    return $.ajax({
      url: `documents/documents/${target_ids.documentId}/versions/${target_ids.versionId}/edit`,
      type: 'get',
      success: data => {
        commit('SET_DOCUMENT', data);
        Promise.resolve(data.version);
      },
      error: error => {
        return Promise.reject(error);
      }
    });
  },

  editSidebarDocument({ commit }, documentId) {
    return $.ajax({
      // Since we're moving the whole document we'll just get the latest version instead
      // of an explicit version by ID
      url: `documents/documents/${documentId}/latest_version`,
      type: 'get',
      success: data => {
        commit('SET_EDIT_SIDEBAR_DOCUMENT', data);
        Promise.resolve(data.version);
      },
      error: error => {
        return Promise.reject(error);
      }
    });
  },

  getLatestVersion({ commit }, documentId: string) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${documentId}/latest_version`,
        type: 'get',
        success: data => {
          commit('SET_DOCUMENT', data);
          resolve(data.version);
        },
        error: reject
      });
    });
  },

  getLatestPublishedVersion({ commit }, documentId: string) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${documentId}/latest_published_version`,
        type: 'get',
        success: data => {
          commit('SET_DOCUMENT', data);
          resolve(data);
        },
        error: reject
      });
    });
  },

  get({ commit }, target_ids: TargetVersionIds) {
    return $.ajax({
      url: `documents/documents/${target_ids.documentId}/versions/${target_ids.versionId}/edit`,
      type: 'get',
      success: data => {
        commit('SET_DOCUMENT', data);
        Promise.resolve(data.version);
      },
      error: error => {
        return Promise.reject(error);
      }
    });
  },

  update({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${payload.documentId}`,
        type: 'put',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
          document: payload
        }),
        success: data => {
          dispatch('getLatestVersion', payload.documentId);
          resolve(data.document);
        },
        error: reject
      });
    });
  },

  // I'm not sure that this is the best place for this, but I'm also not
  // sure if it's not.  Maybe consider refactoring the document / versions
  // stuff (and associated controllers) if more comes up
  updateVersion(_, args: VersionArgs) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `documents/documents/${args.documentId}/versions/${args.versionId}`,
        type: 'put',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({
          version: args.attrs
        }),
        success: data => {
          resolve(data);
        },
        error: reject
      });
    });
  },

  publishDraftVersion({ commit }, documentId: Uuid) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading);

    return $.ajax({
      url: `documents/documents/${documentId}/publish_draft`,
      type: 'post',
      success: () => {
        commit('UPDATE_PROGRESS', LoadProgress.Success);
        Promise.resolve();
      },
      error: () => {
        commit('UPDATE_PROGRESS', LoadProgress.Failed);
      }
    });
  },

  showDocument({ commit }, documentId: Uuid) {
    commit('UPDATE_PROGRESS', LoadProgress.Loading);

    return $.ajax({
      url: `documents/documents/${documentId}/show_document`,
      type: 'post',
      success: () => {
        commit('UPDATE_PROGRESS', LoadProgress.Success);
        commit('UPDATE_EDIT_DOCUMENT', { key: 'documentStatus', value: 'active' });
        Promise.resolve();
      },
      error: err => {
        commit('UPDATE_PROGRESS', LoadProgress.Failed);
        Promise.reject(err);
      }
    });
  },

  setCurrentId({ commit }, id: Uuid) {
    commit('SET_CURRENT_ID', id);

    return Promise.resolve();
  },

  clearCurrentId({ commit }) {
    commit('SET_CURRENT_ID', null);
  }
};
