
































































import ActionRequired from 'components/shared/_action_required.vue';
import { mapGetters } from 'vuex';
import { getOrElse, fold } from 'fp-ts/lib/Option';
import { LoadProgress } from 'admin/helpers';

export default {
  components: { ActionRequired },
  data() {
    return {
      logoutProgress: LoadProgress.Init,
    };
  },
  computed: {
    ...mapGetters('UserStore', ['currentUser']),
    user(): User | null {
      return getOrElse(() => null)(this.currentUser);
    },
    currentUserName() {
      return fold(() => 'User', (u: User) => u.firstName + ' ' + u.lastName)(this.currentUser);
    },
    currentRole() {
      return fold(() => '', (u: User) => u.employeeType.toLowerCase())(this.currentUser);
    },
    btnClass() {
      switch (this.logoutProgress) {
      case LoadProgress.Loading:
        return 'loading';
      case LoadProgress.Success:
        return 'loaded success';
      case LoadProgress.Failed:
        return 'loaded failed';
      default:
        return '';
      }
    },
    supportHref() {
      if (window.App.internalHelpContact) {
        const ihc = window.App.internalHelpContact;
        let mailingList = 'mailto:';
        Object.keys(ihc).forEach((contact) => {
          mailingList = mailingList.concat(ihc[contact].value, ';');
        })
        return mailingList;
      } else {
        return 'https://weeverapps.com/support/';
      }
    },
    processFeatureEnabled() {
      return window.App.features.includes('process');
    }
  },
  methods: {
    editProfile() {
      this.$store.commit('UserStore/editCurrentUser');
      this.$root.$emit('bv::show::modal', 'editProfileModal');
    },
    logout() {
      this.logoutProgress = LoadProgress.Loading;
      this.$store.dispatch('UserStore/signOut')
        .then(() => {
          this.logoutProgress = LoadProgress.Success;
          window.location.href = '/';
          this.resetProgess();
        })
        .catch((err) => {
          this.logoutProgress = LoadProgress.Failed;
          this.resetProgess();
          console.error('failed to sign out', err);
        });
    },
    resetProgess() {
      setTimeout(() => this.logoutProgress = LoadProgress.Init, 1000);
    }
  }
};
