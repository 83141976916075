


































































import { LoadProgress } from 'admin/helpers';

export default {
  props: {
    klass: String,
    value: String,
    progress: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      LoadProgress,
      isLoadedSuccess: false,
      isLoadedFailed: false
    };
  },

  computed: {
    isDisabled() {
      return this.disabled || this.isLoading || this.isLoadedSuccess;
    },
    isLoading() {
      return this.progress === LoadProgress.Loading;
    }
  },

  watch: {
    progress(value: LoadProgress) {
      if (value === LoadProgress.Success) {
        this.isLoadedSuccess = true;
        setTimeout(this.reset, 1000);
      }
      if (value === LoadProgress.Failed) {
        this.isLoadedFailed = true;
        setTimeout(this.reset, 1000);
      }
    }
  },

  methods: {
    reset() {
      this.isLoadedSuccess = false;
      this.isLoadedFailed = false;
    }
  }
};
