import { LoadProgress } from 'admin/helpers';
import { BaseState, baseAssetMutations, buildState } from 'admin/state/base_store';

interface ProcessCategory {
  id: number;
  name: string;
}

interface ProcessCategoryState extends BaseState {
  process_categories: ProcessCategory[];
  process_category: ProcessCategory;
  status: LoadProgress;
}

const ProcessCategoryStore = {
  namespaced: true,
  state: buildState<ProcessCategoryState>({
    process_categories: [],
    process_category: {
      status: 'published',
    },
    status: LoadProgress.Init,
  }),
  mutations: {
    ...baseAssetMutations('process_category', 'process_categories'),
    updateStatus(state: ProcessCategoryState, newValue: LoadProgress) {
      state.status = newValue;
    }
  },
  actions: {
    index(context) {
      context.commit('updateStatus', LoadProgress.Loading);
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/process_categories',
          type: 'get',
          data: {
            per_page: Number.MAX_SAFE_INTEGER,
            order_by: 'name',
            order_desk: 'ASC'
          },
          success: (data) => {
            context.commit('many', data);
            context.commit('updateStatus', LoadProgress.Success);
            resolve(data);
          },
          error: reject
        });
      });
    },
    paginated(context, query) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/process_categories',
          type: 'get',
          data: query,
          success: (data) => {
            context.commit('many', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    new(context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/process_categories/new',
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(id);
          },
          error: reject
        });
      });
    },
    create(context, form) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: 'admin/process_categories',
          type: 'post',
          data: {
            process_category: form
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    edit(context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/process_categories/${id}`,
          type: 'get',
          success: (data) => {
            context.commit('one', data);
            resolve(data);
          },
          error: reject
        });
      });
    },
    update(context, processCategory) {
      context.commit('progress', 'loading');
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/process_categories/${processCategory.id}`,
          type: 'put',
          data: {
            process_category: processCategory
          },
          success: (data) => {
            context.commit('progress', 'success');
            resolve(data);
          },
          error: (data) => {
            context.commit('progress', 'failed');
            context.commit('errors', data);
            reject(data);
          }
        });
      });
    },
    destroy(_context, id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `admin/process_categories/${id}`,
          type: 'delete',
          success: resolve,
          error: reject
        });
      });
    }
  }
};

export default ProcessCategoryStore;
