



















































































import { mapGetters, mapState } from 'vuex';
import { getOrElse } from 'fp-ts/lib/Option';

export default {
  computed: {
    ...mapState('TaskStore', ['actionRequiredCount', 'requiredTasks']),
    ...mapGetters('UserStore', ['currentUser']),
    user() {
      return getOrElse(() => null)(this.currentUser);
    }
  },
  mounted() {
    this.$store.dispatch('TaskStore/get');
    this.$root.$on('wx::update::user-settings', () => this.$store.dispatch('TaskStore/get'));
  },
  beforeDestroy() {
    this.$root.$off('wx::update::user-settings');
  }
};
