import { ActionContext } from 'vuex';
import { CommentState, Commentable, NewComment } from 'state/comments/types';
import { displayResourceLockedMsg } from 'admin/helpers';
import { getComments, createComment } from '../../api/comments/index';
import { CreateCommentRequest, GetCommentsRequest, GetCommentsResponse } from 'api/comments/types';

// TODO: double check the changes in this PR

type CommentContext = ActionContext<CommentState, CommentState>;

export default {
  index(context: CommentContext, opts: GetCommentsRequest) {
    return getComments(opts)
      .then((data: GetCommentsResponse) => {
        context.commit('many', data.comments);
        return data;
      });
  },
  create(context: CommentContext, opts: CreateCommentRequest) {
    // TODO: move state mutation from SidebarProcessComments.vue
    return createComment(opts);
  },
  documentCommentsIndex(context: CommentContext, { resources, resourceId }: Commentable) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${resources}/${resourceId}/comments`,
        type: 'get',
        success: (data) => {
          context.commit('many', data.comments);
          context.commit('loadDocumentsComments', { id: resourceId, comments: data.comments });
          resolve(data);
        },
        error: reject
      });
    });
  },
  documentCommentsCreate(context: CommentContext, { resources, resourceId, text }: NewComment) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${resources}/${resourceId}/comments`,
        type: 'post',
        data: {
          comment: {
            text
          }
        },
        success: (data) => {
          context.commit('addDocumentComment', resourceId);
          resolve(data);
        },
        error: (err) => {
          if (err.status === 423) {
            displayResourceLockedMsg(context);
          }
          reject(err)
        }
      });
    });
  }
};
