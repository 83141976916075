import { ProcessState, UpdateRequestBody, TaskUpdatedMessage } from 'state/processes/types';
import { UpdateNonRecurringTaskLastUpdatedMessage, UpdateRecurringTaskLastUpdatedMessage, UpdateSingleProcessMessage } from 'api/messages';
import { 
  firstStartedIndex,
  buildSubmissionUrl,
  getSelectedSubmission,
  stripUploadErrors,
  buildUpdateRequestBody,
  submissionUpdateSuccess,
  buildCopyRequestBody,
  buildCopySubmissionUrl,
  buildUpdateSummaryRequestBody,
  isCurrentProcess,
} from 'state/processes/helpers';
import { ActionContext } from 'vuex';
import {
  LoadProgress,
  displayOfflineMsg,
  displayResourceLockedMsg
} from 'admin/helpers';
import {
  any,
  path,
  pathOr,
  pathEq,
  propEq,
  clone,
  pick,
  keys
} from 'ramda';
import {
  isNone,
  map as mapOption
} from 'fp-ts/lib/Option';
import { getTask } from 'admin/helpers/processes';
import { diff } from 'deep-object-diff';

const getTaskStatusLabel = (taskStatus: string) => window.App.taskStatusLabels.find((taskStatusLabel) => taskStatusLabel.value === taskStatus).text

export default {
  create(context: ActionContext<ProcessState, ProcessState>, process) {
    return new Promise((resolve, reject) => {
      context.commit('progress', LoadProgress.Loading);
      const submission = context.state.submissionInfo.submission.values;

      $.ajax({
        url: 'processes',
        type: 'post',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify({ process, submission }),
        success: data => {
          context.commit('progress', LoadProgress.Success);
          context.commit('one', data.process);
          context.dispatch('taskRuleToasts', { id: data.process.id, notifications: data.notifications })
          resolve(data.process);
        },
        error: err => {
          context.commit('progress', LoadProgress.Failed);
          context.commit('errors', err);
          reject(err);
        }
      });
    });
  },
  paginated(context, query) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'processes',
        type: 'get',
        data: query,
        success: data => {
          context.commit('many', data);
          resolve(data);
        },
        error: reject
      });
    });
  },
  instances(context, query) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'process_instances',
        type: 'get',
        data: query,
        success: data => {
          context.commit('many', data);
          resolve(data);
        },
        error: reject
      });
    });
  },
  get(context, { processId }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `processes/${processId}`,
        type: 'get',
        success: data => {
          context.commit('one', data.process);
          resolve(data.process);
        },
        error: reject
      });
    });
  },
  getActivityHistory(context, processId) {
    context.commit('historyProgress', LoadProgress.Loading);

    return new Promise((resolve, reject) => {
      $.ajax({
        url: `processes/${processId}/events`,
        type: 'get',
        success: data => {
          context.commit('setActivityHistory', data.events);
          resolve(data);
        },
        error: reject
      });
    });
  },
  getTaskGrid(
    context: ActionContext<ProcessState, ProcessState>,
    { processId, stepId, taskId }
  ) {
    const url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/submissions_grid`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'get',
        success: data => {
          const submissionInfo = {
            processId,
            stepId,
            taskId,
            ...data
          };

          submissionInfo.selectedSubmission = firstStartedIndex(
            data.submissions
          );

          context.commit('submissionsGrid', data.submissions);
          resolve(data);
        },
        error: err => {
          context.commit('errors', err);
          reject(err);
        }
      });
    });
  },
  getTaskSubmission(
    context: ActionContext<ProcessState, ProcessState>,
    { processId, stepId, taskId }
  ) {
    context.commit('submissionInfo', null);
    const url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/submission`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'get',
        success: data => {
          const submissionInfo = {
            processId,
            stepId,
            taskId,
            ...data
          };

          submissionInfo.selectedSubmission = firstStartedIndex(
            data.submissions
          );

          // Update TaskStore with the newly created task.
          context.dispatch(
            'TaskStore/addTask',
            {
              ...submissionInfo.task,
              stepId: submissionInfo.stepId,
              processId: submissionInfo.processId
            },
            { root: true }
          );

          context.commit('submissionInfo', submissionInfo);
          resolve(data);
        },
        error: err => {
          context.commit('errors', err);
          reject(err);
        }
      });
    });
  },
  selectSubmission(context: ActionContext<ProcessState, ProcessState>, { submissionId, index }: { submissionId: Uuid; index: number; }) {
    const url = `submissions/${submissionId}`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'get',
        success: submission => {
          context.commit('setSubmission', { submission, index });
          context.commit('selectSubmission', index);
          resolve(submission);
        },
        error: err => {
          context.commit('errors', err);
          reject(err);
        }
      });
    });
  },
  closeProcess(
    context: ActionContext<ProcessState, ProcessState>,
    id: string
  ) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `processes/${id}/close`,
        type: 'put',
        success: data => {
          resolve(data);
        },
        error: reject
      });
    });
  },
  reopenProcess(
    context: ActionContext<ProcessState, ProcessState>,
    id: string
  ) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `processes/${id}/reopen`,
        type: 'put',
        success: data => {
          resolve(data);
        },
        error: reject
      });
    });
  },
  skipTaskSubmission(
    context: ActionContext<ProcessState, ProcessState>,
    { reason }: { reason: string; }
  ) {
    const url = buildSubmissionUrl(context.state.submissionInfo) + '/skip';
    const body = { submission: { reason } };

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        data: body,
        success: resolve,
        error: reject
      });
    });
  },
  signaturePrevalidation(context: ActionContext<ProcessState, ProcessState>, signatureId: Uuid) {
    const submissionInfo = context.state.submissionInfo;
    const submissionInfoCache = context.state.submissionInfoCache;

    if (isNone(submissionInfo.selectedSubmission)) {
      return Promise.resolve({});
    }

    let submission = clone(getSelectedSubmission(submissionInfo));
    const submissionCache = getSelectedSubmission(submissionInfoCache);    
    const submissionDiff = diff(submissionCache.values, submission.values);
    
    submission.values = pick(keys(submissionDiff), submission.values);
    submission = stripUploadErrors(submissionInfo, submission);
    
    const selectedSubmission = submissionInfo.selectedSubmission;
    const body: UpdateRequestBody = buildUpdateRequestBody(
      submission,
      selectedSubmission
    );

    const url = buildSubmissionUrl(submissionInfo) + `/signature_prevalidation/${signatureId}`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'post',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify(body),
        success: resolve,
        error: err => {
          console.error('Error in signaturePrevalidation', err);
          reject(err);
        }
      });
    });
  },
  updateSubmission(context: ActionContext<ProcessState, ProcessState>) {
    const submissionInfo = context.state.submissionInfo;
    const submissionInfoCache = context.state.submissionInfoCache;

    let submission = clone(getSelectedSubmission(submissionInfo));
    const submissionCache = getSelectedSubmission(submissionInfoCache);    
    const submissionDiff = diff(submissionCache.values, submission.values);
    
    submission.values = pick(keys(submissionDiff), submission.values);
    submission = stripUploadErrors(submissionInfo, submission);
    
    const selectedSubmission = submissionInfo.selectedSubmission;
    const body: UpdateRequestBody = buildUpdateRequestBody(
      submission,
      selectedSubmission
    );

    const url = buildSubmissionUrl(submissionInfo);
    context.commit('submissionProgress', LoadProgress.Loading);

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify(body),
        success: data => {
          submissionUpdateSuccess(context)(data);
          resolve(data);
        },
        error: err => {
          if (pathEq(['message'], 'Failed to fetch', err)) {
            displayOfflineMsg(context);
          } else if (err.status === 423) {
            displayResourceLockedMsg(context);
          }
          context.commit('errors', err);
          context.commit('submissionProgress', LoadProgress.Failed);
          reject(err);
        }
      });
    });
  },
  copySubmission(
    context: ActionContext<ProcessState, ProcessState>,
    requiredElements: Uuid[]
  ) {
    const submissionInfo = context.state.submissionInfo;
    let submission = getSelectedSubmission(submissionInfo);
    submission = stripUploadErrors(submissionInfo, submission);
    const selectedSubmission = submissionInfo.selectedSubmission;
    const body: UpdateRequestBody = buildCopyRequestBody(
      submission,
      selectedSubmission,
      requiredElements
    );
    const url = buildCopySubmissionUrl(submissionInfo);

    context.commit('submissionProgress', LoadProgress.Loading);

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify(body),
        success: () => {
          context.commit('submissionProgress', 'success');
          const toast = {
            color: 'success',
            title: 'Success',
            text: 'A new form copy was created.'
          };
          context.dispatch('ToastStore/toast', toast, { root: true });

          const process = context.state.process;
          const processId = process.id;
          const taskId = submissionInfo.taskId;
          const step = process.steps.find(s => any(propEq('id', taskId), s.tasks));
          const stepId = step.id;
          const task = step.tasks.find(t => t.id === taskId);

          const opts = {
            processId,
            stepId,
            taskId,
            task
          };

          resolve(
            context.dispatch('getTaskSubmission', opts)
          );
        },
        error: err => {
          displayOfflineMsg(context);
          context.commit('errors', err);
          context.commit('submissionProgress', LoadProgress.Failed);
          reject(err);
        }
      });
    });
  },
  updateSummary(context: ActionContext<ProcessState, ProcessState>) {
    const process = context.state.process;
    const body = buildUpdateSummaryRequestBody(process);
    const url = `processes/${process.id}/updateSummary`;

    context.commit('summaryProgress', LoadProgress.Loading);

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        data: body,
        success: data => {
          context.commit('summaryProgress', 'success');
          resolve(data);
        },
        error: err => {
          context.dispatch('ToastStore/toast',
            {
              text: 'Failed to update process summary.',
              color: 'danger'
            },
            { root: true }
          );
          context.commit('errors', err);
          context.commit('summaryProgress', LoadProgress.Failed);
          reject(err);
        }
      });
    });
  },
  updateSingleProcess(
    context: ActionContext<ProcessState, ProcessState>,
    message
  ) {
    const processes: Process[] = path(['state', 'processes'], context);
    const process =
      processes && processes.find(p => p.id === message.process_id);

    if (process) {
      process.next_step_title = message.next_step_title || '';
      process.next_task_title = message.next_task_title || '';
      process.next_check = message.next_check;
    }
  },

  addStep(context, { processId, step }) {
    context.commit('stepProgress', LoadProgress.Loading);
    const url = `processes/${processId}/steps`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'post',
        data: { step },
        success: res => {
          context.commit('stepProgress', 'success');
          resolve(res);
        },
        error: err => {
          context.commit('stepProgress', LoadProgress.Failed);
          context.commit('stepErrors', err);
          reject(err);
        }
      });
    });
  },
  addTask(context, { processId, stepId, task }) {
    context.commit('taskProgress', LoadProgress.Loading);
    const url = `processes/${processId}/steps/${stepId}/tasks`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'post',
        data: { task },
        success: res => {
          context.commit('taskProgress', LoadProgress.Success);
          context.commit('updateProcessTask', {
            task: res.task,
            step: stepId
          });

          context.commit('TaskStore/SET_TASK', task, { root: true });
          resolve(res);
        },
        error: err => {
          context.commit('taskProgress', LoadProgress.Failed);
          context.commit('taskErrors', err);
          reject(err);
        }
      });
    });
  },
  processSummaryUpdated(context, message) {
    const process =
      context.state.processes &&
      context.state.processes.find(p => p.id === message.id);

    if (process) {
      process.line_work_area = pathOr('', ['line_work_area'], message);
      process.live_process_col_1 = pathOr(
        '',
        ['summary', 'values', 'column1'],
        message
      );
      process.live_process_col_2 = pathOr(
        '',
        ['summary', 'values', 'column2'],
        message
      );
    }

    if (isCurrentProcess(context.state, message.id)) {
      const toast = {
        color: 'success',
        title: 'Success',
        text: 'Process summary updated.'
      };
      context.commit('processSummaryUpdated', message);
      context.dispatch('ToastStore/toast', toast, { root: true });
    }
  },
  stepAdded(context, message) {
    if (isCurrentProcess(context.state, message.id)) {
      const step = context.state.process.steps.find(
        s => s.id === message.stepId
      );
      if (!step) {
        const toast = {
          color: 'success',
          title: 'Sucess',
          text: 'Step added successfully.'
        };
        context.commit('stepAdded', message);
        context.dispatch('ToastStore/toast', toast, { root: true });
      }
    }
  },
  taskAdded({ commit, dispatch, state }, message) {
    if (isCurrentProcess(state, message.id)) {
      const step = state.process.steps.find(s => s.id === message.stepId);
      if (step) {
        const task = step.tasks.find(t => t.id === message.task.id);

        if (!task) {
          // Update TaskStore with the newly created task.
          dispatch(
            'TaskStore/addTask',
            { ...message.task, stepId: message.stepId, processId: state.process.id },
            { root: true }
          );

          // Update the process object with the task.
          commit('taskAdded', message);
          dispatch('ToastStore/toast',
            {
              color: 'success',
              title: 'New task created',
              text: `New task “${ message.task.title }” was added to the process.`
            },
            { root: true }
          );
        }
      }
    }
    const process =
      state.processes && state.processes.find(p => p.id === message.id);
    if (process) {
      process.next_task_title = pathOr('', ['nextTaskTitle'], message);
      process.next_step_title = pathOr('', ['nextStepTitle'], message);
    }
  },
  taskRuleToasts(context, message) {
    const notifications = message.notifications;

    if (isCurrentProcess(context.state, message.id)) {
      const notRequiredStatusLabel = getTaskStatusLabel('not_required');
      const generateToastInfo = function(taskStatus) {
        switch(taskStatus) {
        case 'not_required':
          if (context.rootState.TaskStore.showNotRequiredTasks === false){
            return ['Some tasks were hidden', `The status of one or more tasks has changed to "${notRequiredStatusLabel}" and hidden from view due to answers within a form.`];
          } else {
            return [`Tasks marked "${notRequiredStatusLabel}"`, `The status of one or more tasks has changed to "${notRequiredStatusLabel}" due to answers within a form.`];
          }
        case 'required':
          return ['Task list updated', 'Some tasks are now required for process completion due to answers within a form.'];
        default:
          return ['Task Rule Warning', 'A task rule could not be processed. Please resubmit the form or contact support.'];
        }
      }

      notifications.forEach( (notification) => {
        const toastInfo = generateToastInfo(notification)
        const toast = {
          color: 'info',
          toast_type: notification,
          title: toastInfo[0],
          text: toastInfo[1]
        };
        context.dispatch('ToastStore/toast', toast, { root: true });
      });
    }
  },
  taskStatusUpdated(context, message) {
    if (isCurrentProcess(context.state, message.id)) {
      const step = context.state.process.steps.find(
        s => s.id === message.stepId
      );
      if (step) {
        const task = step.tasks.find(t => t.id === message.task.id);
        if (task) {
          const statusActions = {
            stop_task: 'critical_issue',
            start_task: 'in_progress',
            complete_task: 'done',
            invalidate_task: 'not_required'
          };
          task.status = statusActions[message.status];

          context.dispatch(
            'TaskStore/addTask',
            {
              ...task,
              stepId: step.id,
              processId: context.state.process.id
            },
            { root: true }
          );

          context.commit('taskStatusUpdated', message);
          context.commit('updateTaskUpdatedBy', message.task);
        }
      }
    }
  },
  taskLastUpdatedUpdated(context, message: UpdateNonRecurringTaskLastUpdatedMessage | UpdateRecurringTaskLastUpdatedMessage | UpdateSingleProcessMessage) {
    if ('id' in message) {
      const process =
      context.state.processes &&
      context.state.processes.find(p => p.id === message.id);

      if (process) {
        process.updated_at = message.task.lastUpdatedAt;
      }

      if (isCurrentProcess(context.state, message.id)) {
        const step = context.state.process.steps.find(
          s => s.id === message.stepId
        );
        if (step) {
          const task = step.tasks.find(t => t.id === message.task.id);
          if (task) {
            context.commit('updateTaskUpdatedBy', message.task);
          }
        }
      }
    }

    if ('id' in message) {
      message.task['processId'] = message.id;
    }
    if ('processId' in message) {
      message.task['processId'] = message.processId;
    }
    if ('nextCheck' in message) {
      message.task['nextCheck'] = message.nextCheck;
    }

    context.commit('TaskStore/UPDATE_TASK', { task: message.task }, { root: true });
  },
  processStatusUpdated(context, message) {
    if (isCurrentProcess(context.state, message.id)) {
      const title =
        message.data.state === 'closed' ? 'Process Closed' : 'Process Reopened';
      const text =
        message.data.state === 'closed'
          ? 'Process was closed.'
          : 'Process re-opened successfully.';
      context.commit('processStatusUpdated', message.data);
      context.dispatch('ToastStore/toast',
        { color: 'success', title, text },
        { root: true }
      );
    }
  },
  submissionCopied(context, message) {
    if (isCurrentProcess(context.state, message.id)) {
      context.commit('submissionCopied', message);
    }
  },
  taskNextCheckUpdated(context, message: TaskUpdatedMessage) {
    if (isCurrentProcess(context.state, message.id)) {
      const taskO = getTask(
        context.state.process,
        message.stepId,
        message.task.id
      );

      mapOption((task: ProcessTask) => {
        context.commit('taskNextCheckUpdated', message);
        task.nextCheck =
          message.task.next_check || message.msg_options.next_check;
        context.dispatch(
          'TaskStore/addTask',
          {
            ...task,
            stepId: message.stepId,
            processId: context.state.process.id
          },
          { root: true }
        );
      })(taskO);
    }
  },
  emailPDF(context, { processId, recipient_ids }) {
    const url = `processes/${processId}/email_summary`;
    context.commit('sendPdfProgress', LoadProgress.Loading);
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'post',
        data: { recipient_ids },
        success: data => {
          context.commit('sendPdfProgress', LoadProgress.Success);
          resolve(data);
        },
        error: errors => {
          context.commit('sendPdfProgress', LoadProgress.Failed);
          context.commit('sendPdfErrors', errors);
          reject(errors);
        }
      });
    });
  },
  emailSubmissionPDF(context, { process_id, submission_id, recipient_ids }) {
    context.commit('sendSubmissionPdfProgress', LoadProgress.Loading);
    return new Promise((resolve, reject) => {
      const url = `submissions/${submission_id}/email`;
      $.ajax({
        url,
        type: 'post',
        data: { process_id, recipient_ids },
        success: data => {
          context.commit('sendSubmissionPdfProgress', LoadProgress.Success);
          resolve(data);
        },
        error: err => {
          context.commit('sendSubmissionPdfProgress', LoadProgress.Failed);
          context.commit('sendSubmissionPdfErrors', err);
          reject(err);
        }
      });
    });
  }
};
