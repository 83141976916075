import actions from 'state/tasks/actions';
import getters from 'state/tasks/getters';
import mutations from 'state/tasks/mutations';
import state from 'state/tasks/state';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
