import actions from 'state/folders/actions';
import mutations from 'state/folders/mutations';
import state from 'state/folders/state';

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
