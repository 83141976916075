import { removeCookie, setCookie } from 'helpers/cookies';
import idle from 'helpers/idleSession';
import { InitState } from 'state/session/state';

interface RailsTimeoutCheck {
  has_timed_out: boolean;
}
export interface IdleOptions {
  onIdle: () => void;
  idle: number;
}

export default {
  setup({ commit }, init: InitState) {
    commit('SETUP_SESSION', init);
  },

  init({ commit, dispatch }) {
    return Promise.all([
      dispatch('initAlertOptions'),
      dispatch('initWarningOptions')
    ]).then(([alertOptions, warningOptions]: IdleOptions[]) => {
      const newSession = idle.initSession(alertOptions, warningOptions);

      commit('INIT_SESSION', newSession);
    });
  },

  initWarningOptions({ dispatch, state }): Promise<IdleOptions> {
    const onIdle = async () => {
      const sessionExpired: RailsTimeoutCheck = await dispatch(
        'confirmRailsSession'
      );

      if (sessionExpired.has_timed_out) {
        return state.showAlertModal();
      }

      return state.showWarningModal();
    };

    return Promise.resolve({
      idle: state.warningTimeout,
      onIdle
    });
  },

  initAlertOptions({ state }): Promise<IdleOptions> {
    return Promise.resolve({
      idle: state.alertTimeout,
      onIdle: () => {
        state.hideWarningModal();

        setTimeout(() => {
          state.showAlertModal();
        }, 2000);
      }
    });
  },

  start({ commit }) {
    commit('START_SESSION');
  },

  restart({ commit }) {
    commit('RESTART_SESSION');

    // Ping the API to ensure the session is restarted properly.
    $.ajax({
      url: 'session/reset_timeout',
      type: 'get'
    });
  },

  stop({ commit }) {
    commit('STOP_SESSION');
  },

  clearNextRoute() {
    return removeCookie('nextRoutePath');
  },

  setNextRoute(_, path: string) {
    return setCookie('nextRoutePath', { path });
  },

  confirmRailsSession() {
    return $.ajax({
      url: 'session/expired',
      type: 'get',
      success: (response: RailsTimeoutCheck): RailsTimeoutCheck => response
    });
  }
};
