import state from 'admin/state/users/state';
import mutations from 'admin/state/users/mutations';
import actions from 'admin/state/users/actions';
import getters from 'admin/state/users/getters';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
