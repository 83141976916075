import actions from 'state/comments/actions';
import mutations from 'state/comments/mutations';
import state from 'state/comments/state';

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
