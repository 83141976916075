import CalculationResultService from 'admin/components/forms/elements/CalculationResult/CalculationResult.service';
import CheckboxFieldsetService from 'admin/components/forms/elements/CheckboxFieldset/CheckboxFieldset.service';
import DateFieldService from 'admin/components/forms/elements/DateField/DateField.service';
import DecimalFieldService from 'admin/components/forms/elements/DecimalField/DecimalField.service';
import EmailFieldService from 'admin/components/forms/elements/EmailField/EmailField.service';
import TelephoneFieldService from 'admin/components/forms/elements/TelephoneField/TelephoneField.service';
import NumberCheckFieldService from 'admin/components/forms/elements/NumberCheckField/NumberCheckField.service';
import RadioFieldsetService from 'admin/components/forms/elements/RadioFieldset/RadioFieldset.service';
import SignatureFieldService from 'admin/components/forms/elements/SignatureField/SignatureField.service';
import TextFieldService from 'admin/components/forms/elements/TextField/TextField.service';
import UserLookupService from 'admin/components/forms/elements/UserLookup/UserLookup.service';
import LineLookupService from 'admin/components/forms/elements/LineLookup/LineLookup.service';
import MachineLookupService from 'admin/components/forms/elements/MachineLookup/MachineLookup.service';
import DropDownService from 'admin/components/forms/elements/DropDown/DropDown.service';
import InfoBoxService from 'admin/components/forms/elements/InfoBox/InfoBox.service';
import PasswordFieldService from 'admin/components/forms/elements/PasswordField/PasswordField.service';
import ShiftLookupService from 'admin/components/forms/elements/ShiftLookup/ShiftLookup.service';
import DoubleBlindService from 'admin/components/forms/elements/DoubleBlind/DoubleBlind.service';
import FileUploadService from 'admin/components/forms/elements/FileUpload/FileUpload.service';
import ParagraphFieldService from 'admin/components/forms/elements/ParagraphField/ParagraphField.service';
import LiveProcessColumnFieldService from 'admin/components/forms/elements/LiveProcessColumnField/LiveProcessColumnField.service';
import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';
import ActionItemService from 'admin/components/forms/elements/ActionItem/ActionItem.service';
import { has } from 'ramda';

const services = {
  TextField: TextFieldService,
  SignatureField: SignatureFieldService,
  RadioFieldset: RadioFieldsetService,
  DateField: DateFieldService,
  DecimalField: DecimalFieldService,
  CalculationResult: CalculationResultService,
  CheckboxFieldset: CheckboxFieldsetService,
  NumberCheckField: NumberCheckFieldService,
  UserLookup: UserLookupService,
  LineLookup: LineLookupService,
  MachineLookup: MachineLookupService,
  DropDown: DropDownService,
  InfoBox: InfoBoxService,
  PasswordField: PasswordFieldService,
  ShiftLookup: ShiftLookupService,
  DoubleBlind: DoubleBlindService,
  FileUpload: FileUploadService,
  EmailField: EmailFieldService,
  ParagraphField: ParagraphFieldService,
  TelephoneField: TelephoneFieldService,
  ActionItem: ActionItemService,
  LiveProcessColumnField: LiveProcessColumnFieldService
};

/* eslint-disable complexity */
export const getService = (control: string): (FormBuilderElementService<any> | null) => {
  if (has(control, services)) {
    return services[control];
  }
  return null;
};
/* eslint-enable complexity */
