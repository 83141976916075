import helpers, { FieldData } from 'admin/helpers';
import { PaginatedUsers } from 'admin/state/users/types';

const autocompleteUsers = async (
  context,
  fieldData: FieldData
): Promise<PaginatedUsers> => {
  const queryParams = helpers.buildQueryFromFieldData(fieldData);

  return new Promise((resolve, reject) => {
    $.ajax({
      url: 'users/autocomplete',
      type: 'get',
      data: queryParams,
      success: data => {
        context.commit('many', data);
        resolve(data);
      },
      error: reject
    });
  });
};

const fetchUsers = async (
  context,
  fieldData: FieldData
): Promise<PaginatedUsers> => {
  const queryParams = helpers.buildQueryFromFieldData(fieldData);

  return new Promise((resolve, reject) => {
    $.ajax({
      url: 'users',
      type: 'get',
      data: queryParams,
      success: data => {
        context.commit('many', data);
        resolve(data);
      },
      error: reject
    });
  });
};

const createUser = (url: string, context: any, user: any): Promise<User> => {
  if (user.avatar) {
    user.avatar.value = { 0: user.avatar.value[0] };
  }
  context.commit('progress', 'loading');
  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      type: 'post',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ user }),
      success: (data: User) => {
        context.commit('progress', 'success');
        context.commit('clearUser');
        resolve(data);
      },
      error: data => {
        context.commit('progress', 'failed');
        context.commit('errors', data);
        reject(data);
      }
    });
  });
};

export default {
  async index(context, fieldData: FieldData): Promise<PaginatedUsers> {
    return await fetchUsers(context, fieldData);
  },
  async autocomplete(context, fieldData: FieldData): Promise<User[]> {
    const data = await autocompleteUsers(context, fieldData);
    return data.users;
  },
  async checkIfUserPotentiallyCompromised(): Promise<boolean> {
    // We don't really care about the response - the server will send a message
    // via WebSockets if there is an issue
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/sessions/check',
        type: 'get',
        success: resolve,
        error: reject
      });
    });
  },
  create(context, user) {
    return createUser('/admin/users', context, user);
  },
  register(context, user) {
    return createUser('/users', context, user);
  },
  signOut(context) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/users/sign_out',
        type: 'delete',
        dataType: 'json',
        contentType: 'application/json',
        success: res => {
          window.currentUser = null;
          context.commit('clearUser');
          resolve(res);
        },
        error: reject
      });
    });
  },
  signatureAuthentication(_context, { email, password }) {
    return new Promise((resolve, reject) => {
      const data = JSON.stringify({
        user: {
          email,
          password
        }
      });
      $.ajax({
        url: 'users/validate',
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        data,
        success: user => {
          resolve(user);
        },
        error: reject
      });
    });
  },
  edit(context, id) {
    $.ajax({
      url: `admin/users/${id}/edit`,
      type: 'get',
      success: data => {
        context.commit('edit', data);
      }
    });
  },
  update(context, user) {
    context.commit('progress', 'loading');
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `admin/users/${user.id}`,
        type: 'put',
        data: {
          user
        },
        success: data => {
          context.commit('progress', 'success');
          resolve(data);
        },
        error: data => {
          context.commit('progress', 'failed');
          context.commit('errors', data);
          reject(data);
        }
      });
    });
  },
  updateCurrentUser(context, user) {
    context.commit('progress', 'loading');
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'users',
        type: 'put',
        data: {
          user
        },
        success: data => {
          context.commit('progress', 'success');
          resolve(data);
        },
        error: data => {
          context.commit('progress', 'failed');
          context.commit('errors', data);
          reject(data);
        }
      });
    });
  },
  updateUserNotificationSettings(context, user) {
    context.commit('progress', 'loading');
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'users/update_notifications',
        type: 'put',
        data: {
          user
        },
        success: data => {
          context.commit('progress', 'success');
          resolve(data);
        },
        error: data => {
          context.commit('progress', 'failed');
          context.commit('errors', data);
          reject(data);
        }
      });
    });
  },
  // send a notification email to new users
  notifyNewUser(_context, newUser) {
    const url = `users/${newUser.id}/email_notification`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        data: { id: newUser.id },
        success: data => {
          resolve(data);
        },
        error: errs => {
          reject(errs);
        }
      });
    });
  },

  notifyEnabledUser(_context, userId: number) {
    const url = `users/${userId}/enabled_notification`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        data: { id: userId },
        success: data => {
          resolve(data);
        },
        error: errs => {
          reject(errs);
        }
      });
    });
  }
};
