import { isNil } from 'ramda';
import { TaskState, ExtendedTask } from 'state/tasks/types';

export default {
  incompleteTask: (state: TaskState): ExtendedTask | void => {
    if (state.incompleteTaskId) {
      return state.byId[state.incompleteTaskId];
    }

    return null;
  },

  hasIncompleteTask: (state: TaskState): boolean => !!state.incompleteTaskId,

  tasks: (state: TaskState) => (ids: Uuid[]): ExtendedTask[] =>
    ids.map((id: Uuid) => state.byId[id]).filter((t) => !isNil(t)),

  filteredTasks: (state: TaskState) => (ids: Uuid[]): ExtendedTask[] => {
    const tasks = ids.map((id: Uuid) => state.byId[id]);
    if (state.showNotRequiredTasks) {
      return tasks;
    }
    return tasks.filter((task: ExtendedTask) => task.status !== 'not_required');
  }
};
