













export default {
  props: {
    id: String,
    klass: String,
    dataTestid: String,
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
