import { FormBuilderElementService } from 'admin/components/forms/elements/interfaces';

export interface SignatureField extends FormElement {
  type: 'SignatureField';
  name: string;
  label: string;
  content: 'Review' | 'Review and Approval' | 'Responsibility';
  children: Uuid[];
  required: boolean;
  all_or_specific: string;
  placeholder?: string;
}

const service: FormBuilderElementService<SignatureField> = {
  getEmptyElement: (id: string): SignatureField => {
    return {
      id,
      name: '',
      label: 'Please add your signature',
      content: 'Review',
      type: 'SignatureField',
      children: [],
      required: false,
      all_or_specific: 'all',
      placeholder: 'Please select one…'
    };
  }
};

export default service;
